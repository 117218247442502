import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, fromLogin } from './selectors';
import { Observable, defer, of } from 'rxjs';
import { LoginAction } from './actions';

@Injectable({ providedIn: 'root' })
export class LoginFacadeService {
  constructor(private store: Store<State>) {}

  getRefresh(): Observable<boolean> {
    return this.store.select(fromLogin.getRefresh);
  }
  getToken(): Observable<string | null> {
    return this.store.select(fromLogin.getToken);
  }

  getPantallas(): Observable<any | null> {
    return this.store.select(fromLogin.getPantallas);
  }

  setToken({ token, pantallas }: { token: any; pantallas: any }): void {
    this.store.dispatch(LoginAction.setToken({ token, pantallas }));
  }

  refreshToken(): Observable<void> {
    return defer(() => of(this.store.dispatch(LoginAction.refreshToken({}))));
  }

  getError(): Observable<string | null> {
    return this.store.select(fromLogin.getError);
  }

  findToken(): void {
    this.store.dispatch(LoginAction.findToken({}));
  }
}
