import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Numbers } from '@constants/numbers';
import { TipoDeActivosList } from '@models/AssetTypes';
import { CanalesList } from '@models/Channels';
import { FondeoList } from '@models/Funds';
import { ICreateOperation } from '@models/ICreateOperation';
import { ICurrentRates } from '@models/ICurrentRates';
import { ILoadData } from '@models/ILoadData';
import { IResponsibleBusiness } from '@models/IResponsibleBusiness.interfaces';
import { ISelectBox } from '@models/ISelectBox';
import { ProductosList } from '@models/Product';
import {
  ActiveApproves,
  ActivosXCotizacionPaginados,
  Arrendatarios,
  ClientInformation,
  DatosactivosPag,
  IAssetRisk,
  IAssetRiskList,
  ICodeMora,
  IListProjectName,
  IRates,
  ISaveAssetsDeliveryDataSuccess,
  ISaveDeliveryData,
  ISaveDeliveryDataSuccess,
  ListActiveMarkPage,
  ListUnitsPage,
  OperationByContract,
  RentOperation,
} from '@models/RentOperation';
import { IAssetsInfoToReserve, ReservePlatesData } from '@models/ReservePlates';
import { IMakeDtoAssetTermBegins } from '@modules/master/rent-operation/lease/deliveries/asset-deliveries/asset-deliveries.component';
import { ApiHelperService } from '@services/apihelper/api-helper.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ILegalFile } from 'src/app/core/models/ILegalFile.interface';
import { ILegalRepresentative } from 'src/app/core/models/ILegalRepresentative.interface';
import { INonExistingAssets } from 'src/app/core/models/INonExistingAssets.interface';
import { backend } from 'src/paths/development';

@Injectable({
  providedIn: 'root',
})
export class RentOperationService {
  constructor(private http: ApiHelperService) {}

  nullContractReport({ initialDate, finalDate }: any): Observable<any> {
    return this.http
      .get(
        `${backend.rentOperation.nullContractReport}?fechaInicial=${initialDate}&fechaFinal=${finalDate}`,
        '',
        {
          responseType: 'blob',
        }
      )
      .pipe(
        map((blob: any) => {
          const link = document.createElement('a');
          link.download = `reporte_${initialDate}_${finalDate}.xlsx`;
          link.href = URL.createObjectURL(blob);
          link.click();
          return blob;
        })
      );
  }

  createRentOperation({
    createRentOperationDto,
  }: {
    createRentOperationDto: any;
  }): Observable<any> {
    return this.http.post(`${backend.rentOperation.createOperation}`, createRentOperationDto);
  }

  releaseActiveByPlate({ body }: { body: IAssetsInfoToReserve }): Observable<{ message: string }> {
    return this.http.post(`${backend.rentOperation.releasePlate}`, body);
  }

  nullContract({ numContract, descriptionNull }: any): Observable<any> {
    return this.http.put(`${backend.rentOperation.nullContract}`, {
      numeroContrato: numContract,
      motivoAnulacion: descriptionNull,
    });
  }

  reserveAssets({ plates }: { plates: IAssetsInfoToReserve[] }): Observable<ReservePlatesData> {
    return this.http.post(`${backend.rentOperation.reserveAssets}`, plates);
  }

  releaseAssets({ reserveIds }: { reserveIds: string[] }): Observable<any> {
    return this.http.post(`${backend.rentOperation.releaseAssets}`, reserveIds);
  }

  findBuyers({ docClient }: { docClient?: string }): Observable<any> {
    return this.http.get(`${backend.rentOperation.buyers}?numeroDocumento=${docClient}`);
  }

  /**
   * Get a ILegalRepresentative by DocNumber
   * @param {
   *  docNumber: string
   * }
   * @returns Observable<ILegalRepresentative>
   * @Rep Representante
   */
  getRepByDocClient({
    docNumber,
    page,
    size,
  }: {
    [key: string]: any;
  }): Observable<ILegalRepresentative> {
    return this.http
      .get(
        `${backend.rentOperation.getByDocClient}?documentoCliente=${docNumber}&page=${page}&size=${size}`
      )
      .pipe(map((response: any) => response.representanteslegalesresponse));
  }

  /**
   * Get a ILegalFile by DocNumber
   * @param DocNumber: DocNumber
   * @returns Observable<ILegalFile>
   */
  getLegalFileByDocClient(DocNumber: string): Observable<ILegalFile | undefined | null> {
    const headers = new HttpHeaders().set('Content-Location', 'Consultar Ficha Juridica');
    const options = { headers: headers };
    return this.http
      .get(`${backend.rentOperation.apiLegalFile}?documentoCliente=${DocNumber}`, '', options)
      .pipe(map(this.ILegalFileMapper));
  }

  getMarks({
    codTipoActivo = '10',
    codGrupo = '102',
    codSubGrupo = '107',
  }: {
    codTipoActivo?: string;
    codGrupo?: string;
    codSubGrupo?: string;
  }): Observable<any> {
    return this.http.get(
      `${backend.rentOperation.marks}?codTipoActivo=${codTipoActivo}&codGrupo=${codGrupo}&codSubGrupo=${codSubGrupo}`
    );
  }

  ILegalFileMapper = (response?: any | undefined) => response?.fichaJuridica as ILegalFile;

  /**
   * Post Filter by requestBody
   * @param requestBody: requestBody
   * @returns Observable<INonExistingAssets[]>
   */
  saveFileExcelAssets(requestBody: File): Observable<INonExistingAssets[]> {
    const fromData = new FormData();
    fromData.append('file', requestBody, requestBody?.name);
    return this.http
      .post(`${backend.rentOperation.apiExcelAssets}`, fromData)
      .pipe(map((response: any) => response.activos as INonExistingAssets[]));
  }

  /**
   * rentOperationModule returns rent operation data
   * @param {
   *  approvalValue: string
   * }
   * @returnsObservable<RentOperation>
   */
  rentOperationModule({ approvalValue }: { [key: string]: any }): Observable<RentOperation> {
    return this.http
      .get(`${backend.rentOperation.rentOperationModule}?aprobacion=${approvalValue}`)
      .pipe(map(this.rentOperationMapper));
  }

  /**
   * rentOperationModule returns rent arrendatarios data
   * @param {
   *  noAprobacion: string,
   *  noIdentificacion: string
   * }
   * @returnsObservable<Arrendatarios>
   */
  arrendatarioModule({
    noAprobation,
    noIdentification,
  }: {
    [key: string]: any;
  }): Observable<Arrendatarios> {
    return this.http
      .get(
        `${backend.rentOperation.arrendatarios}?noAprobacion=${noAprobation}&noIdentificacion=${noIdentification}`
      )
      .pipe(map((response: Arrendatarios) => response));
  }

  rentOperationMapper = (data: { creacionoperacionrenta: RentOperation }) =>
    data.creacionoperacionrenta;

  /**
   * activeApproves returns an active approves of rent operation
   * @param {
   *  docClient: string
   * }
   * @returns Observable<ActiveApproves>
   */
  activeApproves({
    docClient,
    page = Numbers._0,
    size = Numbers._5,
  }: {
    [key: string]: any;
  }): Observable<ActiveApproves> {
    return this.http.get(
      `${backend.rentOperation.activeApproves}?docCliente=${docClient}&page=${page}&size=${size}`
    );
  }

  /**
   * findClientInformation returns the client information of rent operation
   * @param {
   *  name: string,
   *  docClient: string
   * }
   * @returns Observable<ClientInformation>
   */
  findClientInformation({
    name,
    docClient,
    tipoDoc = '',
  }: {
    [key: string]: any;
  }): Observable<ClientInformation[]> {
    return this.http
      .post(`${backend.rentOperation.getClientInformation}`, {
        nombreCliente: name,
        docCliente: docClient,
        tipoDoc,
      })
      .pipe(map(this.clientInformationMapper));
  }

  clientInformationMapper = (data: any) => data.datosCliente;

  /**
   * Get all the products
   * @param codigo: filter of the elemets
   * @param descripcion: filter of the elements to show per page
   * @returns Observable<ProductosList[]>
   */
  getProductsByFilter(codigo: string = '', descripcion: string = ''): Observable<ProductosList[]> {
    return this.http
      .get(`${backend.products.getProductsByFilter}?codigo=${codigo}&descripcion=${descripcion}`)
      .pipe(map((response: any) => response.productos as ProductosList[]));
  }

  getAssetsTypeByFilter(
    codigo: string = '',
    descripcion: string = ''
  ): Observable<TipoDeActivosList[]> {
    return this.http
      .get(`${backend.assetType.getByFilter}?codigo=${codigo}&descripcion=${descripcion}`)
      .pipe(map((response: any) => response.tiposdeactivos as TipoDeActivosList[]));
  }

  getChannelsByFilter(codigo: string = '', descripcion: string = ''): Observable<CanalesList[]> {
    return this.http
      .get(`${backend.channel.getByFilter}?codigo=${codigo}&descripcion=${descripcion}`)
      .pipe(map((response: any) => response.canal as CanalesList[]));
  }

  getFundsByFilter(codigo: string = '', descripcion: string = ''): Observable<FondeoList[]> {
    return this.http
      .get(`${backend.funds.getByFilter}?codigo=${codigo}&descripcion=${descripcion}`)
      .pipe(map((response: any) => response.fondeo as FondeoList[]));
  }

  getAssetsCondition(): Observable<ILoadData[]> {
    return this.http
      .get(`${backend.rentOperation.getAssetsCondition}`)
      .pipe(map((response: any) => response.condicionActivo as ILoadData[]));
  }

  getAccountManagerByFilter(codigo: string = '', nombre: string = ''): Observable<ILoadData[]> {
    return this.http
      .get(
        `${backend.rentOperation.getAccountManager}?codigoUsuario=${codigo}&nombreUsuario=${nombre}`
      )
      .pipe(map((response: any) => response.operadores as ILoadData[]));
  }

  getRespBusinessByFilter(
    codigo: string = '',
    nombre: string = ''
  ): Observable<IResponsibleBusiness[]> {
    return this.http
      .get(
        `${backend.rentOperation.getResponsibleBusiness}?codigoUsuario=${codigo}&nombreUsuario=${nombre}`
      )
      .pipe(map((response: any) => response.operadores as IResponsibleBusiness[]));
  }

  getAllOperationType(): Observable<ISelectBox[]> {
    return this.http
      .get(`${backend.rentOperation.getOperationType}`)
      .pipe(map((response: any) => response.tipoOperacion as ISelectBox[]));
  }

  getGroupMapper = (data: any) => data.grupos;
  getGroup({
    codeGroup,
    codeActiveType,
  }: {
    codeGroup: string;
    codeActiveType: string;
  }): Observable<{ codigo: string; descripcion: string }[]> {
    return this.http
      .get(
        `${backend.rentOperation.getGroup}?codGrupo=${codeGroup}&codTipoActivo=${codeActiveType}`
      )
      .pipe(map(this.getGroupMapper));
  }

  getSubGroupMpper = (data: any) => data.subgrupos;
  getSubGroup({
    codeGroup,
    codeActiveType,
  }: {
    codeGroup: string;
    codeActiveType: string;
  }): Observable<any> {
    return this.http
      .get(
        `${backend.rentOperation.getGroup}?codTipoActivo=${codeActiveType}&codGrupo=${codeGroup}`
      )
      .pipe(map(this.getSubGroupMpper));
  }

  getResid({
    monthTerm,
    subGroup,
    codeActiveType,
    group,
  }: {
    monthTerm: string;
    subGroup: string;
    codeActiveType: string;
    group: string;
  }): Observable<any> {
    return this.http.get(
      `${backend.rentOperation.getGroup}?plazo=${monthTerm}&codSubGrupo=${subGroup}&codTipoActivo=${codeActiveType}&codGrupo=${group}`
    );
  }
  getSupplier({ docSupplier, nameSupplier }: { [key: string]: any }): Observable<any> {
    /*
    UnComment this
    return this.http.get(
      `${backend.rentOperation.supplier}?docProveedor=${docSupplier}&nombreProveedor=${nameSupplier}`
    );
    */
    return of([
      {
        nombre: 'nombre',
        docClient: '123456',
      },
    ]).pipe(delay(Numbers._2000));
  }

  /**
   * Get all the tasas
   * @returns Observable<IRates[]>
   */
  getRatesData(): Observable<IRates[]> {
    return this.http
      .get(`${backend.rentOperation.rates}`)
      .pipe(map((response: any) => response.tasas as IRates[]));
  }

  getCodeMora({ code, description }: { [key: string]: any }): Observable<ICodeMora[]> {
    return this.http
      .get(`${backend.rentOperation.codeMora}?cod=${code}&des=${description}`)
      .pipe(map(this.codeMoraMapper));
  }
  codeMoraMapper = (data: any) => data.mora;
  getReLeaseTypesMapper = (data: any) => data.tipoRearriendo;
  /**
   * getReLeaseTypes return a list of IReLeaseType model
   */
  getReLeaseTypes(): Observable<ISelectBox[]> {
    return this.http.get(backend.rentOperation.reLeaseType).pipe(map(this.getReLeaseTypesMapper));
  }

  definitiveOperation({ data }: { data: ICreateOperation }): Observable<any> {
    return this.http.post(backend.rentOperation.createOperation, data);
  }

  getActivesPerQuotationMapper = (response: ActivosXCotizacionPaginados) => response.datosactivos;
  getActivesPerQuotation({
    quotation,
    term,
    tipoActivo,
    page,
    size,
  }: {
    quotation: string;
    term: string;
    tipoActivo: string;
    page: number;
    size: number;
  }): Observable<DatosactivosPag> {
    return this.http
      .get(
        `${backend.rentOperation.activesPerQuotation}?cotizacion=${quotation}&tipoActivo=${tipoActivo}&plazo=${term}&page=${page}&size=${size}`
      )
      .pipe(map(this.getActivesPerQuotationMapper));
  }

  getControlLists({
    docCliente,
    nombreCliente,
    tipoDoc,
  }: {
    docCliente: string;
    nombreCliente: string;
    tipoDoc: string;
  }): Observable<{ listascontrol: { [key: string]: any } }> {
    return this.http.post(`${backend.rentOperation.controlLists}`, {
      tipoDoc,
      docCliente,
      nombreCliente,
    });
  }
  getControlSignatoriesLists(list: any[]): Observable<{ listascontrol: { [key: string]: any } }> {
    return this.http.post(`${backend.rentOperation.controlSignatoriesLists}`, list);
  }

  /**
   * Get a ILegalRepresentative
   * @returns Observable<ILegalRepresentative>
   * @Rep Representante
   */
  getProjectList(): Observable<IListProjectName> {
    return this.http
      .get(`${backend.rentOperation.getNameProjectList}?nomProyecto=`)
      .pipe(map((response: any) => response.nombreproyecto.listaProyectos));
  }

  /**
   * Get a ListUnitsPage
   * @returns Observable<ListUnitsPage>
   * @Rep Representante
   */
  getUnitsListPage({
    nomProject,
    tipoActivo,
    page,
    size,
  }: {
    [key: string]: any;
  }): Observable<ListUnitsPage> {
    return this.http.get(
      `${backend.rentOperation.getNameProjectByActive}?nomProyecto=${nomProject}&tipoActivo=${tipoActivo}&page=${page}&size=${size}`
    );
  }

  /**
   * Get a ListActiveMarkPage
   * @returns Observable<ListActiveMarkPage>
   * @Rep Representante
   */
  getActiveMarkPage({
    codTipoActivo,
    codGrupo,
    codSubGrupo,
    activoMarca,
    page,
    size,
  }: {
    [key: string]: any;
  }): Observable<ListActiveMarkPage> {
    return this.http.get(
      `${backend.rentOperation.getMarksByActive}?codTipoActivo=${codTipoActivo}&codGrupo=${codGrupo}&codSubGrupo=${codSubGrupo}&activoMarca=${activoMarca}&page=${page}&size=${size}`
    );
  }

  /**
   * Get Operation by contractNumber
   * @param contractNumber: contractNumber
   * @returns Observable<OperationByContract>
   */
  getOperatioByContract({
    contractNumber,
  }: {
    contractNumber: string;
  }): Observable<OperationByContract> {
    return this.http
      .get(`${backend.rentOperation.getOperationByContract}?numContrato=${contractNumber}`)
      .pipe(map((response: OperationByContract) => response));
  }

  postDeliveryData({ data }: { data: ISaveDeliveryData }): Observable<ISaveDeliveryDataSuccess> {
    return this.http
      .post(`${backend.rentOperation.postDeliveryData}`, data)
      .pipe(map((response: any) => response as ISaveDeliveryDataSuccess));
  }

  getCurrentRatesConsultation(): Observable<ICurrentRates> {
    return this.http
      .get(`${backend.rentOperation.checkCurrentRates}`)
      .pipe(map((response) => response as ICurrentRates));
  }

  getActivesByPlates({
    term,
    plates,
  }: {
    term: any;
    plates: IAssetsInfoToReserve[];
  }): Observable<any[]> {
    return this.http
      .post(`${backend.rentOperation.activesByPlates}?plazo=${term}`, plates)
      .pipe(map((response: any) => response.activoporplaca.activos));
  }

  postDeliveryBeginsTermAsset({
    data,
  }: {
    data: IMakeDtoAssetTermBegins;
  }): Observable<ISaveAssetsDeliveryDataSuccess> {
    return this.http
      .post(`${backend.rentOperation.postDeliveryBeginsTerm}`, data)
      .pipe(map((response: any) => response as ISaveAssetsDeliveryDataSuccess));
  }

  getAssets({ body, page, size }: { [key: string]: any }): Observable<any> {
    return this.http
      .post(`${backend.rentOperation.getAssets}?page=${page}&size=${size}`, body)
      .pipe(map((response: any) => response.consultapornumerocontrato));
  }

  /**
   * @returns response:
   */
  getRisksAsset(): Observable<IAssetRiskList[]> {
    return this.http
      .get(`${backend.rentOperation.getRisksAsset}`)
      .pipe(map((response: IAssetRisk) => response.riesgoActivo));
  }

  public destinyWithoutPag = new BehaviorSubject<string | null>(null);
  destinyWithoutPagValues$ = this.destinyWithoutPag.asObservable();

  setConsultDestiny(consult: 'CONSULTAR'): void {
    this.destinyWithoutPag.next(consult);
  }

  resetConsultDestiny(): void {
    this.destinyWithoutPag.next(null);
  }
}
