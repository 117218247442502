import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LoginFacadeService } from '@modules/autenticacion/login/store/login.facade.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomHttpInterceptorBearer implements HttpInterceptor {
  constructor(
    private router: Router,
    public msalService: MsalService,
    private loginFacadeService: LoginFacadeService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Content-Location': this.router.routerState.snapshot.url,
      },
    });
    if (request.url.endsWith('rentas/login')) {
      const token = this.extractMSALToken();
      if (token == null) {
        this.router.navigate(['']);
      } else {
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
      }
    } else {
      this.loginFacadeService.getToken().subscribe({
        next: (token) => {
          if (token == null) {
            this.router.navigate(['']);
          } else {
            request = request.clone({
              setHeaders: {
                Authorization: `${token}`,
              },
            });
          }
        },
      });
    }
    return next.handle(request).pipe(map((data) => data));
  }

  manageLoginExpired = (next: HttpHandler, request: HttpRequest<any>) =>
    this.loginFacadeService.refreshToken().pipe(switchMap(() => next.handle(request)));

  extractMSALTokenAccess(): string | any | null {
    const key = Object.keys(localStorage).find((k) => k.includes('accesstoken')) ?? String();
    const data = JSON.parse(localStorage.getItem(key) ?? JSON.stringify({}));
    if (data.secret === null || data.secret === undefined) {
      localStorage.removeItem(key);
      return null;
    }
    return data.secret;
  }
  extractMSALToken(): string | null {
    const key = Object.keys(localStorage).find((k) => k.includes('idtoken')) ?? String();
    const data = JSON.parse(localStorage.getItem(key) ?? JSON.stringify({}));
    if (data.secret === null || data.secret == undefined) {
      localStorage.removeItem(key!);
      return null;
    }
    return data.secret;
  }
}
