import { Injectable } from '@angular/core';
import { IParameter } from 'src/app/core/models/IParameters.interface';
import { ISelectBox } from 'src/app/core/models/ISelectBox';
import { Subject } from 'rxjs';
import { ParametersTypeEnum } from 'src/app/core/enums/parameters-type.enum';
import { monthsArray } from './helpers-functions';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public parameters: IParameter[] = [];
  public currentIp = new Subject<string>();

  constructor() {
    //This is intentional
  }

  public setCurrentIp(title: string) {
    this.currentIp.next(title);
  }

  getParams(type: ParametersTypeEnum | null, subclass?: ParametersTypeEnum) {
    if (!this.parameters) {
      return [];
    }
    let typeDoc: ISelectBox[] = this.selectTypeParameteres(type!);
    if (subclass) {
      const opl = (
        typeDoc.filter((subc) => subc.description.toUpperCase() === subclass.toUpperCase())[0] ?? {
          optionsParameterList: [],
        }
      ).optionsParameterList;
      typeDoc = [];
      if (opl) {
        opl.forEach((obj, i) => {
          typeDoc.push({
            id: i,
            description: obj,
          });
          i++;
        });
      }
    }
    return typeDoc;
  }

  selectTypeParametersWhenTypeIsActiveOrInactive(): ISelectBox[] {
    return [
      { id: 1, name: 'Activo' },
      { id: 0, name: 'Inactivo' },
    ].map((obj) => ({ id: obj?.id, description: obj?.name }));
  }

  selectTypeParametersWhenTypeIsActionVariable(): ISelectBox[] {
    return [
      { id: 0, name: 'Parámetros' },
      { id: 1, name: 'Editar' },
    ].map((obj) => ({ id: obj?.id, description: obj?.name }));
  }

  selectTypeParametersWhenTypeIsPeriodicity(): ISelectBox[] {
    return [
      { id: 0, name: 'Mensual' },
      { id: 1, name: 'Trimestral' },
      { id: 2, name: 'Semestral' },
      { id: 3, name: 'Anual' },
      { id: 4, name: 'Anual año corrido' },
      { id: 5, name: 'Anual a diciembre 31 año anterior' },
    ].map((obj) => ({ id: obj?.id, description: obj?.name }));
  }

  selectTypeParametersWhenTypeIsReadjustmentFormula(): ISelectBox[] {
    return [
      { id: 0, name: 'Compuesta' },
      { id: 1, name: 'Nominal' },
      { id: 2, name: 'Data' },
      { id: 3, name: 'Data' },
    ].map((obj) => ({
      id: obj?.id,
      description: obj?.name,
    }));
  }

  selectTypeParametersWhenTypeIsDaysBase(): ISelectBox[] {
    return [
      { id: 0, name: 'Base 360 días' },
      { id: 1, name: 'Base 365 días' },
    ].map((obj) => ({ id: obj?.id, description: obj?.name }));
  }

  selectTypeParametersWhenTypeIsNumberMonths(): ISelectBox[] {
    return monthsArray.map((obj) => ({
      id: obj.id,
      description: obj.name,
    }));
  }

  selectTypeParametersWhenTypeIsFile(): ISelectBox[] {
    return [
      { id: 0, name: 'Excel' },
      { id: 1, name: 'CSV' },
    ].map((obj) => ({ id: obj?.id, description: obj?.name }));
  }

  selectTypeParameteres(type: ParametersTypeEnum) {
    if (type === ParametersTypeEnum.ACTIVE_OR_INACTIVO) {
      return this.selectTypeParametersWhenTypeIsActiveOrInactive();
    } else if (type === ParametersTypeEnum.ACTION_VARIABLE) {
      return this.selectTypeParametersWhenTypeIsActionVariable();
    } else if (type == ParametersTypeEnum.PERIODICITY) {
      return this.selectTypeParametersWhenTypeIsPeriodicity();
    } else if (type === ParametersTypeEnum.READJUSTMENT_FORMULA) {
      return this.selectTypeParametersWhenTypeIsReadjustmentFormula();
    } else if (type === ParametersTypeEnum.NUMBER_MONTHS) {
      return this.selectTypeParametersWhenTypeIsNumberMonths();
    } else if (type === ParametersTypeEnum.DAYS_BASE) {
      return this.selectTypeParametersWhenTypeIsDaysBase();
    } else if (type === ParametersTypeEnum.FILE_TYPE) {
      return this.selectTypeParametersWhenTypeIsFile();
    } else {
      return this.parameters
        .filter((obj) => obj.parameterTypesData.type.toUpperCase() === type.toUpperCase())
        .map((obj) => ({
          id: obj?.id,
          description: obj?.value,
          optionsParameterList: obj?.optionsParameterList,
        }));
    }
  }
}
