import {
  ActiveApprovesData,
  DestinoList,
  Destiny,
  EconomicGroup,
  Information,
  Observation,
  ValueDetails,
  Warranty,
} from '@models/ActOfApproval';
import { TipoDeActivosList } from '@models/AssetTypes';
import { IParametersTypeOperation } from '@models/Caracteristicas';
import { CreateLeaseDto } from '@models/CreateLeaseDto';
import { CreateOperationDto } from '@models/CreateOperationDto';
import { DataColumn } from '@models/DataColumn';
import { CondicionesFinancieras } from '@models/FinancialConditions';
import { ICreateLease } from '@models/ICreateLease';
import { ICurrentRates, ParameterizedRates, PeriodicityReadjustment } from '@models/ICurrentRates';
import { ILegalFile } from '@models/ILegalFile.interface';
import { ILegalRepresentative } from '@models/ILegalRepresentative.interface';
import { ILoadData } from '@models/ILoadData';
import { IResponsibleBusiness } from '@models/IResponsibleBusiness.interfaces';
import { ISelectBox } from '@models/ISelectBox';
import { ProductosList } from '@models/Product';
import {
  ActiveApproves,
  Arrendatarios,
  ClientInformation,
  ICodeMora,
  IListProjectName,
  IRates,
  ListActiveMarkPage,
  ListUnitsPage,
  OperationByContract,
  RentOperation,
} from '@models/RentOperation';
import { RentOperationDto } from '@models/RentOperationDto';
import { IAssetsInfoToReserve, ReservePlatesData } from '@models/ReservePlates';
import { createAction, props } from '@ngrx/store';

enum RentOperationActionType {
  postCustomerByFilterAction = '[CreateRentOperationComponent] post Customers',
  postCustomerByFilterSuccessAction = '[CreateRentOperationComponent] post Customers By Filter Success',
  errorAction = '[CreateRentOperationComponent] Execute action failure',
  setSelectedClientAction = '[CreateRentOperationComponent] Set selected client',
  findApprovalValuesAction = '[CreateRentOperationComponent] Find the approval values list on the backend',
  setApprovalValuesListAction = '[CreateRentOperationComponent] Set the approval values list got from the backed',
  findRentOperationValuesAction = '[CreateRentOperationComponent] Find rent operation values',
  setRentOperationValuesAction = '[CreateRentOperationComponent] Set the rent operation values into the store',
  findLegalFileDataAction = '[CreateRentOperationComponent] Find the legal file data',
  setLegalFileDataAction = '[CreateRentOperationComponent] Set the legal file data',
  findLegalRepresentativeDataAction = '[CreateRentOperationComponent] Find LegalRepresentativeData',
  setLegalRepresentativeDataAction = '[CreateRentOperationComponent] Set LegalRepresentativeData',
  setApprovalValuesDataAction = '[CreateRentOperationComponent] Set approval values data',
  findApprovalValuesDataAction = '[CreateRentOperationComponent] Find approval values data',
  setDetailValuesDataAction = '[CreateRentOperationComponent] Set detail values data',
  findDetailValuesDataAction = '[CreateRentOperationComponent] Find detail values data',
  findDestinationDataAction = '[CreateRentOperationComponent] Find destination data',
  setDestinationDataAction = '[CreateRentOperationComponent] Set destination data',
  findInfActApprovalDataAction = '[CreateRentOperationComponent] Find information data',
  setInfActApprovalDataAction = '[CreateRentOperationComponent] Set information act approval data',
  setWarrantyDataAction = '[CreateRentOperationComponent] Set warranty data',
  findWarrantyDataAction = '[CreateRentOperationComponent] Find warranty data',
  setObservationDataAction = '[CreateRentOperationComponent] Set observation data',
  findObservationDataAction = '[CreateRentOperationComponent] Find observation data',
  setEconomicGroupDataAction = '[CreateRentOperationComponent] Set economic group data',
  findEconomicGroupDataAction = '[CreateRentOperationComponent] Find economic group data',
  findProductsByFilterAction = '[CreateRentOperationComponent] Find products by filter data',
  setProductsByFilterAction = '[CreateRentOperationComponent] Set products by filter data',
  setSelectedProductByFilterAction = '[CreateRentOperationComponent] Set selected product by filter data',
  findRespBusinessAction = '[CreateOperationComponent] Find resp business data',
  setRespBusinessAction = '[CreateOperationComponent] Set resp business data',
  setSelectedRespBusinessAction = '[CreateOperationComponent] Set selected resp business data',
  findAccountManagerByFilterAction = '[CreateOperationComponent] Find account manager By Filter data',
  setAccountManagerByFilterAction = '[CreateOperationComponent] Set account manager by filter data',
  setSelectedAccountManagerByFilterAction = '[CreateOperationComponent] Set selected account manager by filter data',
  findAssetsByFilterAction = '[CreateOperationComponent] Find assets by filter data',
  setAssetsByFilterAction = '[CreateOperationComponent] Set assets by filter data',
  setSelectedAssetsByFilterAction = '[CreateOperationComponent] Find selected assets by filter data',
  findAssetsConditionAction = '[CreateOperationComponent] Find assets condition data',
  setAssetsConditionAction = '[CreateOperationComponent] Set assets condition data',
  setSelectedAssetsConditionAction = '[CreateOperationComponent] Set selected assets condition data',
  findChannelsByFilterAction = '[CreateOperationComponent] Find channels by filter data',
  setChannelsByFilterAction = '[CreateOperationComponent] Set channels by filter data',
  setSelectedChannelsByFilterAction = '[CreateOperationComponent] Set selected channels by filter data',
  findFundsByFilterAction = '[CreateOperationComponent] Find funds by filter data',
  setFundsByFilterAction = '[CreateOperationComponent] Set funds by filter data',
  setSelectedFundsByFilterAction = '[CreateOperationComponent] Set selected funds by filter data',
  findOperationTypeAction = '[CreateOperationComponent] Find operation type data',
  findOperationCharacteristicAction = '[CreateOperationComponent] Find operation characteristic data',
  setOperationTypeAction = '[CreateOperationComponent] Set operation type data',
  setOperationCharacteristicAction = '[CreateOperationComponent] Set operation characteristic data',
  findRatesAction = '[CreateDefinitiveOperationComponent] Find rates data',
  setRateAction = '[CreateDefinitiveOperationComponent] Set rate data',
  setSelectedRateAction = '[CreateDefinitiveOperationComponent] Set rate selected',
  findCodeMoraAction = '[CreateDefinitiveOperationComponent] Find CodeMora data',
  setCodeMoraAction = '[CreateDefinitiveOperationComponent] Set CodeMora data',
  setSelectedCodeMoraAction = '[CreateDefinitiveOperationComponent] Set CodeMora selected',
  setCreateLeaseAction = '[CreateRentOperationComponent] Set create lease data',
  resetStateAction = '[CreateRentOperationComponent] Set initial state',
  findCodeTasaByFilterAction = '[CreateRentOperationComponent] Find code tasa by filter',
  findProjectNameListAction = '[CreateRentOperationComponent] Find project list name',
  setProjectNameListAction = '[CreateRentOperationComponent] set project list name',
  setActiveApproveAction = '[CreateRentOperationComponent] Set active approves',
  findArrendatariosAction = '[CreateRentOperationComponent] Find arrendatario code',
  setArrendatariosAction = '[CreateRentOperationComponent] set arrendatario code',
  findUnitsActiveListAction = '[CreateRentOperationComponent] Find units list code',
  setUnitsActiveAction = '[CreateRentOperationComponent] set units list code',
  findActivesMarkAction = '[CreateRentOperationComponent] find Actives Mark list code',
  setActivesMarkAction = '[CreateRentOperationComponent] set Actives Mark list code',
  setDestinyListAction = '[CreateRentOperationComponent] set destiny list',
  onResetFlagsAction = '[CreateRentOperationComponent] reset error to null',
  setReservePlatesIdAction = '[CreateRentOperationComponent] set reserve id action',
  setDataCreateLeaseAction = '[CreateLeaseComponent] Set data create lease',
  findOpByContractNumberAction = '[ConsultOperationComponent] find operation by contract number',
  setOpByContractNumberSuccessAction = '[ConsultOperationComponent] set operation by contract number',
  onResetFlagsOpByContractNumberAction = '[DeliveriesComponent] reset flags operation by contract number',
  findCurrentRatesAction = '[CreateDefinitiveOperationComponent] find current rates',
  setCurrentRatesSuccessAction = '[CreateDefinitiveOperationComponent] set current rates',
  onResetFlagsCurrentRatesAction = '[CreateDefinitiveOperationComponent] reset flags currentRates',
  setSelectedDataFnlCondsAction = '[CreateDefinitiveOperationComponent] Set dataFinancialConditions',
  setRentOperationDtoAction = '[CreateRentOperation] set rent operation dto',
  setCreateOperationDtoAction = '[CreateRentOperation] set create operation dto',
  setCreateLeaseDtoAction = '[CreateRentOperation] set create lease dto',
  findPlatesReserveAction = '[CreateRentOperation] find plates reserve',
  setReserveIdAction = '[CreateRentOperation] setReserveId',
  findExistentDataColumnAction = '[CreateLeaseComponent] find existent data column',
  findNonExistentDataColumnAction = '[CreateLeaseComponent] find non existent data column',
  setNonExistentDataColumnAction = '[CreateLeaseComponent] ser not existent data column',
  setExistentDataColumnAction = '[CreateLeaseComponent] set existent data column',
  findActivesByPlatesAction = '[CreateLeaseComponent] find actives by plates action',
  setExistentAssetsAction = '[CreateLeaseComponent] set existent assets',
  setSelectedAssetsTypeAction = '[CreateOfferComponent] Set dataAssetTypesSelected',
  resetDataAssetTypesSelectedAction = '[CreateOfferComponent] reset dataAssetTypesSelected to null',
  resetProductsByFilterAction = '[CreateOfferComponent] reset productsByFilterData to null',
  setSelectedCurrentRateAction = '[CreateDefinitiveOperationComponent] Set selectedCurrentRate',
  setSelectedRadjPeriodicityAction = '[CreateDefinitiveOperationComponent] Set selectedRadjPeriodicity',
  setSelectedPaymentPeriodicityAction = '[CreateDefinitiveOperationComponent] Set selectedPaymentPeriodicity',
  setSelectedReadjFormulaAction = '[CreateDefinitiveOperationComponent] Set selectedReadjFormula',
  removePlateFromReduxAction = '[CreateLeaseComponent] remove plate from redux action',
  setSelectedAssetToReserveAction = '[SearchQuoteOrProjectComponent] Set dataAssetToReserve',
  resetDataAssetToReserveAction = '[SearchQuoteOrProjectComponent] reset dataAssetToReserve to null',
  setUrlOrigen = '[CreateRentOperationComponent] set url origen',
}

const postCustomerByFilter = createAction(
  RentOperationActionType.postCustomerByFilterAction,
  props<{ name?: string; docClient?: string }>()
);
const postCustomerByFilterSuccess = createAction(
  RentOperationActionType.postCustomerByFilterSuccessAction,
  props<{ customersList: ClientInformation[] }>()
);

const setSelectedClient = createAction(
  RentOperationActionType.setSelectedClientAction,
  props<{
    client: string;
    description: string;
    accountManager: { code: string; name: string };
    tipoCartera: string;
    calificacionCliente: string;
    indicadorLlave: string;
    idBanco: string;
    tipoPersonaC: string;
    tipoDocCliente: string;
  }>()
);

const findApprovalValuesList = createAction(
  RentOperationActionType.findApprovalValuesAction,
  props<{ docClient: string; page: number; size: number }>()
);

const setApprovalValuesList = createAction(
  RentOperationActionType.setApprovalValuesListAction,
  props<{ approvalValuesList: ActiveApproves }>()
);

const findRentOperationValues = createAction(
  RentOperationActionType.findRentOperationValuesAction,
  props<{ approvalValue: string }>()
);

const setRentOperationValues = createAction(
  RentOperationActionType.setRentOperationValuesAction,
  props<{ rentOperationValues: RentOperation }>()
);

const findLegalFileData = createAction(
  RentOperationActionType.findLegalFileDataAction,
  props<{ docNumber: string }>()
);

const setLegalFileData = createAction(
  RentOperationActionType.setLegalFileDataAction,
  props<{ legalFile: ILegalFile | undefined | null }>()
);

const findLegalRepresentativeData = createAction(
  RentOperationActionType.findLegalRepresentativeDataAction,
  props<{ docNumber: string; page: number; size: number }>()
);

const setLegalRepresentativeData = createAction(
  RentOperationActionType.setLegalRepresentativeDataAction,
  props<{ legalRepresentative: ILegalRepresentative }>()
);

const setApprovalValuesData = createAction(
  RentOperationActionType.setApprovalValuesDataAction,
  props<{ approvalValuesData: any }>()
);

const findApprovalValuesData = createAction(
  RentOperationActionType.findApprovalValuesDataAction,
  props<{ approvalNumber: string; page: number; size: number }>()
);

const setDetailValuesData = createAction(
  RentOperationActionType.setDetailValuesDataAction,
  props<{ detailValues: ValueDetails }>()
);

const findDetailValuesData = createAction(
  RentOperationActionType.findDetailValuesDataAction,
  props<{
    approvalNumber: string;
    numOperacion: string | '';
    numFactura: string | '';
    page: number;
    size: number;
  }>()
);

const setInfActApprovalData = createAction(
  RentOperationActionType.setInfActApprovalDataAction,
  props<{ infActApproval: Information }>()
);

const findInfActApprovalData = createAction(
  RentOperationActionType.findInfActApprovalDataAction,
  props<{ approvalNumber: string }>()
);

const setDestinationData = createAction(
  RentOperationActionType.setDestinationDataAction,
  props<{ destinations: Destiny }>()
);

const findDestinationData = createAction(
  RentOperationActionType.findDestinationDataAction,
  props<{ approvalNumber: string; page: number; size: number | string }>()
);

const setWarrantyData = createAction(
  RentOperationActionType.setWarrantyDataAction,
  props<{ warranty: Warranty }>()
);

const findWarrantyData = createAction(
  RentOperationActionType.findWarrantyDataAction,
  props<{ approvalNumber: string; page: number; size: number }>()
);

const setObservationData = createAction(
  RentOperationActionType.setObservationDataAction,
  props<{ observations: Observation }>()
);

const findObservationData = createAction(
  RentOperationActionType.findObservationDataAction,
  props<{ approvalNumber: string }>()
);

const setEconomicGroupData = createAction(
  RentOperationActionType.setEconomicGroupDataAction,
  props<{ economicGroup: EconomicGroup }>()
);

const findEconomicGroupData = createAction(
  RentOperationActionType.findEconomicGroupDataAction,
  props<{ docClient: string }>()
);

const findProductsByFilter = createAction(
  RentOperationActionType.findProductsByFilterAction,
  props<{ code: string; description: string }>()
);

const setProductsByFilter = createAction(
  RentOperationActionType.setProductsByFilterAction,
  props<{ productsByFilterData: ProductosList[] }>()
);

const setSelectedProductByFilter = createAction(
  RentOperationActionType.setSelectedProductByFilterAction,
  props<{ codeProduct: string; descripProduct: string }>()
);

const findRespBusiness = createAction(
  RentOperationActionType.findRespBusinessAction,
  props<{ code: string; description: string }>()
);

const setRespBusiness = createAction(
  RentOperationActionType.setRespBusinessAction,
  props<{ respBusiness: IResponsibleBusiness[] }>()
);

const setSelectedRespBusiness = createAction(
  RentOperationActionType.setSelectedRespBusinessAction,
  props<{ code: string; description: string }>()
);

const findAccountManagerByFilter = createAction(
  RentOperationActionType.findAccountManagerByFilterAction,
  props<{ code: string; description: string }>()
);

const setAccountManagerByFilter = createAction(
  RentOperationActionType.setAccountManagerByFilterAction,
  props<{ accountManagerByFilter: ILoadData[] }>()
);

const setSelectedAccountManagerByFilter = createAction(
  RentOperationActionType.setSelectedAccountManagerByFilterAction,
  props<{ code: string; description: string }>()
);

const findAssetsByFilter = createAction(
  RentOperationActionType.findAssetsByFilterAction,
  props<{ code: string; description: string }>()
);

const setAssetsByFilter = createAction(
  RentOperationActionType.setAssetsByFilterAction,
  props<{ assetsByFilter: ILoadData[] }>()
);

const setSelectedAssetsByFilter = createAction(
  RentOperationActionType.setSelectedAssetsByFilterAction,
  props<{ code: string; description: string; permiteAnticipado: boolean }>()
);

const findAssetsCondition = createAction(
  RentOperationActionType.findAssetsConditionAction,
  props<any>()
);

const setAssetsCondition = createAction(
  RentOperationActionType.setAssetsConditionAction,
  props<{ assetsCondition: ILoadData[] }>()
);

const setSelectedAssetsCondition = createAction(
  RentOperationActionType.setSelectedAssetsConditionAction,
  props<{ code: string; description: string }>()
);

const findChannelsByFilter = createAction(
  RentOperationActionType.findChannelsByFilterAction,
  props<{ code: string; description: string }>()
);

const setChannelsByFilter = createAction(
  RentOperationActionType.setChannelsByFilterAction,
  props<{ channelsByFilter: ILoadData[] }>()
);

const setSelectedChannelsByFilter = createAction(
  RentOperationActionType.setSelectedChannelsByFilterAction,
  props<{ code: string; description: string }>()
);

const findFundsByFilter = createAction(
  RentOperationActionType.findFundsByFilterAction,
  props<{ code: string; description: string }>()
);

const setFundsByFilter = createAction(
  RentOperationActionType.setFundsByFilterAction,
  props<{ fundsByFilter: ILoadData[] }>()
);

const setSelectedFundsByFilter = createAction(
  RentOperationActionType.setSelectedFundsByFilterAction,
  props<{ code: string; description: string }>()
);

const findOperationType = createAction(
  RentOperationActionType.findOperationTypeAction,
  props<any>()
);

const findOperationCharacteristic = createAction(
  RentOperationActionType.findOperationCharacteristicAction,
  props<{ page: number; size: number; filtro: string; estado: boolean }>()
);

const setOperationType = createAction(
  RentOperationActionType.setOperationTypeAction,
  props<{ operationType: ISelectBox[] }>()
);

const setOperationCharacteristic = createAction(
  RentOperationActionType.setOperationCharacteristicAction,
  props<{ operationCharacteristic: IParametersTypeOperation }>()
);

const findRates = createAction(RentOperationActionType.findRatesAction, props<any>());

const setRates = createAction(
  RentOperationActionType.setRateAction,
  props<{ ratesList: IRates[] }>()
);

const setSelectedRate = createAction(
  RentOperationActionType.setSelectedRateAction,
  props<{ selectedRate: IRates }>()
);

const findArrendatarioData = createAction(
  RentOperationActionType.findArrendatariosAction,
  props<{ noAprobation: string; noIdentification: string }>()
);

const setArrendatarioData = createAction(
  RentOperationActionType.setArrendatariosAction,
  props<{ arrendatarios: Arrendatarios }>()
);

//Codigo de Mora

const findCodeMoraByFilter = createAction(
  RentOperationActionType.findCodeMoraAction,
  props<{ code: string; description: string }>()
);

const setCodeMora = createAction(
  RentOperationActionType.setCodeMoraAction,
  props<{ codeMoraByFilter: ICodeMora[] }>()
);

const setSelectedCodeMora = createAction(
  RentOperationActionType.setSelectedCodeMoraAction,
  props<{ code: string; description: string }>()
);

//End Codigo de Mora

const setCreateLeaseAction = createAction(
  RentOperationActionType.setCreateLeaseAction,
  props<{ createLease: ICreateLease }>()
);

const onError = createAction(RentOperationActionType.errorAction, props<{ error: any }>());

const resetState = createAction(RentOperationActionType.resetStateAction, props<any>());

const setApprovalValues = createAction(
  RentOperationActionType.setApprovalValuesDataAction,
  props<{ approvalValuesData: any }>()
);

const setActiveApproves = createAction(
  RentOperationActionType.setActiveApproveAction,
  props<{ activeApproves: ActiveApprovesData }>()
);

const setDestinyList = createAction(
  RentOperationActionType.setDestinyListAction,
  props<{ destinyList: DestinoList[] }>()
);

const findProjectNameList = createAction(
  RentOperationActionType.findProjectNameListAction,
  props<any>()
);

const setProjectNameList = createAction(
  RentOperationActionType.setProjectNameListAction,
  props<{ projectNameList: IListProjectName }>()
);

const findUnitsActiveList = createAction(
  RentOperationActionType.findUnitsActiveListAction,
  props<{ nomProject: string; tipoActivo: string; page: number; size: number }>()
);

const setUnitsActiveList = createAction(
  RentOperationActionType.setUnitsActiveAction,
  props<{ unitsActiveList: ListUnitsPage }>()
);

const findActivesMarkList = createAction(
  RentOperationActionType.findActivesMarkAction,
  props<{
    codTipoActivo: string;
    codGrupo: string;
    codSubGrupo: string;
    activoMarca: string;
    page: number;
    size: number;
  }>()
);

const setActivesMarkList = createAction(
  RentOperationActionType.setActivesMarkAction,
  props<{ activesMarkList: ListActiveMarkPage }>()
);

const setReservePlatesId = createAction(
  RentOperationActionType.setReservePlatesIdAction,
  props<{ reserveId: string[] }>()
);

const onResetFlagsError = createAction(RentOperationActionType.onResetFlagsAction);

/*ACTIONS SET DATA OPERACION RENTA*/
const setDataCreateLease = createAction(
  RentOperationActionType.setDataCreateLeaseAction,
  props<{ dataCreateLease: any }>()
);

const findOpByContractNumber = createAction(
  RentOperationActionType.findOpByContractNumberAction,
  props<{ contractNumber: string }>()
);

const setOpByContractNumberSuccess = createAction(
  RentOperationActionType.setOpByContractNumberSuccessAction,
  props<{ opByContractNumber: OperationByContract }>()
);

const onResetFlagsOpByContractNumber = createAction(
  RentOperationActionType.onResetFlagsOpByContractNumberAction
);

const findCurrentRates = createAction(RentOperationActionType.findCurrentRatesAction);

const setCurrentRatesSuccess = createAction(
  RentOperationActionType.setCurrentRatesSuccessAction,
  props<{ currentRates: ICurrentRates }>()
);

const onResetFlagsCurrentRates = createAction(
  RentOperationActionType.onResetFlagsCurrentRatesAction
);

const setSelectedDataFnlConds = createAction(
  RentOperationActionType.setSelectedDataFnlCondsAction,
  props<{ dataFinancialConditions: CondicionesFinancieras }>()
);

const setRentOperationDto = createAction(
  RentOperationActionType.setRentOperationDtoAction,
  props<{ createRentOperationDto: RentOperationDto }>()
);

const setCreateOperationDto = createAction(
  RentOperationActionType.setCreateOperationDtoAction,
  props<{ createOperationDto: CreateOperationDto }>()
);

const setCreateLeaseDto = createAction(
  RentOperationActionType.setCreateLeaseDtoAction,
  props<{ createLeaseDto: CreateLeaseDto }>()
);

const findPlatesReserve = createAction(
  RentOperationActionType.findPlatesReserveAction,
  props<{ plates: string[] }>()
);

const setReserveId = createAction(
  RentOperationActionType.setReserveIdAction,
  props<{ reservePlatesData: ReservePlatesData }>()
);

const findExistentDataColumns = createAction(
  RentOperationActionType.findExistentDataColumnAction,
  props<{ codeActiveType: string }>()
);
const findNonExistentDataColumns = createAction(
  RentOperationActionType.findNonExistentDataColumnAction,
  props<{ codeActiveType: string }>()
);

const setNonExistentDataColumn = createAction(
  RentOperationActionType.setNonExistentDataColumnAction,
  props<{ nonExistentDataColumn: DataColumn[] }>()
);
const setExistentDataColumn = createAction(
  RentOperationActionType.setExistentDataColumnAction,
  props<{ existentDataColumn: DataColumn[] }>()
);

const findActivesByPlates = createAction(
  RentOperationActionType.findActivesByPlatesAction,
  props<{ monthTerm: string; plates: IAssetsInfoToReserve[] }>()
);

const setExistentAssets = createAction(
  RentOperationActionType.setExistentAssetsAction,
  props<{ assets: any }>()
);
const setSelectedAssetsType = createAction(
  RentOperationActionType.setSelectedAssetsTypeAction,
  props<{ dataAssetTypesSelected: TipoDeActivosList[] }>()
);

const resetDataAssetTypesSelected = createAction(
  RentOperationActionType.resetDataAssetTypesSelectedAction
);

const resetProductsByFilter = createAction(RentOperationActionType.resetProductsByFilterAction);

const setSelectedCurrentRate = createAction(
  RentOperationActionType.setSelectedCurrentRateAction,
  props<{ selectedCurrentRate: ParameterizedRates }>()
);

const setSelectedRadjPeriodicity = createAction(
  RentOperationActionType.setSelectedRadjPeriodicityAction,
  props<{ selectedRadjPeriodicity: PeriodicityReadjustment }>()
);

const setSelectedPaymentPeriodicity = createAction(
  RentOperationActionType.setSelectedPaymentPeriodicityAction,
  props<{ selectedPaymentPeriodicity: ISelectBox }>()
);

const setSelectedReadjFormula = createAction(
  RentOperationActionType.setSelectedReadjFormulaAction,
  props<{ selectedReadjFormula: ISelectBox }>()
);

const removePlateFromRedux = createAction(
  RentOperationActionType.removePlateFromReduxAction,
  props<{ plate: string }>()
);

const setSelectedAssetToReserve = createAction(
  RentOperationActionType.setSelectedAssetToReserveAction,
  props<{ dataAssetToReserve: any[] }>()
);

const resetDataAssetToReserve = createAction(RentOperationActionType.resetDataAssetToReserveAction);

const findUrlOrigen = createAction(
  RentOperationActionType.setUrlOrigen,
  props<{ origen: string }>()
);

export const RentOperationAction = {
  setExistentAssets,
  removePlateFromRedux,
  findActivesByPlates,
  setNonExistentDataColumn,
  setExistentDataColumn,
  findExistentDataColumns,
  findNonExistentDataColumns,
  setReserveId,
  findPlatesReserve,
  setCreateLeaseDto,
  setRentOperationDto,
  setCreateOperationDto,
  setReservePlatesId,
  setDestinyList,
  findProjectNameList,
  setProjectNameList,
  setActiveApproves,
  postCustomerByFilter,
  postCustomerByFilterSuccess,
  setSelectedClient,
  findApprovalValuesList,
  findRentOperationValues,
  setRentOperationValues,
  setApprovalValuesList,
  findLegalFileData,
  setLegalFileData,
  setApprovalValuesData,
  findLegalRepresentativeData,
  setLegalRepresentativeData,
  findApprovalValuesData,
  setDetailValuesData,
  findDetailValuesData,
  setInfActApprovalData,
  findInfActApprovalData,
  setDestinationData,
  findDestinationData,
  setWarrantyData,
  findWarrantyData,
  setObservationData,
  findObservationData,
  setEconomicGroupData,
  findEconomicGroupData,
  findProductsByFilter,
  setProductsByFilter,
  setSelectedProductByFilter,
  findRespBusiness,
  setRespBusiness,
  setSelectedRespBusiness,
  findAccountManagerByFilter,
  setAccountManagerByFilter,
  setSelectedAccountManagerByFilter,
  findAssetsByFilter,
  setAssetsByFilter,
  setSelectedAssetsByFilter,
  findAssetsCondition,
  setAssetsCondition,
  setSelectedAssetsCondition,
  findChannelsByFilter,
  setChannelsByFilter,
  setSelectedChannelsByFilter,
  setSelectedFundsByFilter,
  findFundsByFilter,
  setFundsByFilter,
  findOperationType,
  findOperationCharacteristic,
  setOperationType,
  setOperationCharacteristic,
  findRates,
  setRates,
  setSelectedRate,
  setCreateLeaseAction,
  onError,
  findCodeMoraByFilter,
  setCodeMora,
  setSelectedCodeMora,
  resetState,
  setApprovalValues,
  findArrendatarioData,
  setArrendatarioData,
  findUnitsActiveList,
  setUnitsActiveList,
  findActivesMarkList,
  setActivesMarkList,
  onResetFlagsError,
  setDataCreateLease,
  findOpByContractNumber,
  setOpByContractNumberSuccess,
  onResetFlagsOpByContractNumber,
  findCurrentRates,
  setCurrentRatesSuccess,
  onResetFlagsCurrentRates,
  setSelectedDataFnlConds,
  setSelectedAssetsType,
  resetDataAssetTypesSelected,
  resetProductsByFilter,
  setSelectedCurrentRate,
  setSelectedRadjPeriodicity,
  setSelectedPaymentPeriodicity,
  setSelectedReadjFormula,
  setSelectedAssetToReserve,
  resetDataAssetToReserve,
  findUrlOrigen,
};
