const BASE_URL = 'arrendamientodigback/rentas/api';
const BASE_URL2 = 'arrendamientodigbackgendoc/rentas/gen-doc';
const BASE_URL3 = 'arrendamientodigbackrecaudos/rentas/recaudos/api';
export const backend = {
  operation: {
    create: `${BASE_URL}/caracteristicas/guardar`,
    update: `${BASE_URL}/caracteristicas/actualizar`,
    getAll: `${BASE_URL}/caracteristicas/obtenertodos`,
    getByCode: `${BASE_URL}/caracteristicas/buscarporcodigo`,
    deactivate: `${BASE_URL}/caracteristicas/desactivar`,
    activate: `${BASE_URL}/caracteristicas/activar`,
  },
  channel: {
    create: `${BASE_URL}/canales/guardar`,
    deactivate: `${BASE_URL}/canales/inacivar`,
    activate: `${BASE_URL}/canales/activar`,
    update: `${BASE_URL}/canales/actualizar`,
    getAll: `${BASE_URL}/canales/buscarTodos`,
    getByCode: `${BASE_URL}/canales/buscarporcodigo`,
    getByFilter: `${BASE_URL}/canales/buscarporfiltros`,
  },
  assetType: {
    create: `${BASE_URL}/tipodeactivos/guardar`,
    deactivate: `${BASE_URL}/tipodeactivos/desactivacodigo`,
    activate: `${BASE_URL}/tipodeactivos/activacodigo`,
    update: `${BASE_URL}/tipodeactivos/actualizar`,
    getAll: `${BASE_URL}/tipodeactivos/obtenertodos`,
    getByCode: `${BASE_URL}/tipodeactivos/buscarporcodigo`,
    getByFilter: `${BASE_URL}/tipodeactivos/buscarporfiltros`,
    getAllColumns: `${BASE_URL}/tablaactivos/buscartodos`,
    getColumsnByType: `${BASE_URL}/tablaactivos/buscarportipo`,
    getAllAssetsTypeWithoutPag: `${BASE_URL}/tipodeactivos/buscartodostiposactivos`,
    saveConfigColumns: `${BASE_URL}/tablaactivos/guardarconfiguracion`,
  },
  funds: {
    create: `${BASE_URL}/fondeo/guardar`,
    deactivate: `${BASE_URL}/fondeo/desactivacodigo`,
    activate: `${BASE_URL}/fondeo/activacodigo`,
    update: `${BASE_URL}/fondeo/actualizar`,
    getAll: `${BASE_URL}/fondeo/obtenertodos`,
    getByCode: `${BASE_URL}/fondeo/buscarporcodigo`,
    getByFilter: `${BASE_URL}/fondeo/buscarporfiltros`,
  },
  products: {
    create: `${BASE_URL}/productos/guardar`,
    deactivate: `${BASE_URL}/productos/desactivacodigo`,
    activate: `${BASE_URL}/productos/activacodigo`,
    update: `${BASE_URL}/productos/actualizar`,
    getAll: `${BASE_URL}/productos/obtenertodos`,
    getByCode: `${BASE_URL}/productos/buscarporcodigo`,
    getProductsByFilter: `${BASE_URL}/productos/buscarporfiltros`,
  },
  assetsParams: {
    assetsTable: `${BASE_URL}/tablaactivos/buscarportipo`,
  },
  rentOperation: {
    releaseAssets: `${BASE_URL}/estadoactivos/desasociar`,
    reserveAssets: `${BASE_URL}/estadoactivos/reservar`,
    getByDocClient: `${BASE_URL}/fichajuridica/representantelegal`,
    apiLegalFile: `${BASE_URL}/fichajuridica/informacion`,
    apiExcelAssets: `${BASE_URL}/excelactivos/cargararchivo`,
    rentOperationModule: `${BASE_URL}/creacionoperacionrenta/modulooperacionrenta`,
    activeApproves: `${BASE_URL}/creacionoperacionrenta/aprobacionesactivas`,
    arrendatarios: `${BASE_URL}/creacionoperacionrenta/arrendatarios`,
    getClientInformation: `${BASE_URL}/cliente/informacion`,
    getAssetsCondition: `${BASE_URL}/condicionactivo/obtenertodos`,
    getAccountManager: `${BASE_URL}/consultaoperador/gerentecuenta`,
    getResponsibleBusiness: `${BASE_URL}/consultaoperador/comercialresponsable`,
    getOperationType: `${BASE_URL}/tipooperacion/obtenertodos`,
    getGroup: `${BASE_URL}/gruposysubgrupos/consultar`,
    supplier: `${BASE_URL}/proveedores/informacion`,
    rates: `${BASE_URL}/tasas/obtenertodos`,
    calculationCode: `${BASE_URL}/codigocalculo/consulta`,
    codeMora: `${BASE_URL}/mora/buscarmora`,
    reLeaseType: `${BASE_URL}/rearriendo/obtenertipos`,
    createOperation: `${BASE_URL}/creacionoperacionrenta/guardaroperacion`,
    activesPerQuotation: `${BASE_URL}/activoscotizacion/consulta`,
    controlLists: `${BASE_URL}/listascontrol/consulta`,
    controlSignatoriesLists: `${BASE_URL}/listascontrol/consultaporfirmantes`,
    marks: `${BASE_URL}/gruposysubgrupos/marcas`,
    activesByPlates: `${BASE_URL}/activosplaca/consultar`,
    buyers: `${BASE_URL}/compradores/consultar`,
    getNameProjectList: `${BASE_URL}/nombreproyectos/consultar`,
    getNameProjectByActive: `${BASE_URL}/nombreproyectos/consultarunidades`,
    getMarksByActive: `${BASE_URL}/gruposysubgrupos/activospormarca`,
    getOperationByContract: `${BASE_URL}/consultaoperacion/pornumerocontrato`,
    postDeliveryData: `${BASE_URL}/entregaactivos/solucion`,
    checkCurrentRates: `${BASE_URL}/tasasparametrizadas/consultarvigentes`,
    nullContract: `${BASE_URL}/creacionoperacionrenta/anularcontrato`,
    releasePlate: `${BASE_URL}/estadoactivos/liberarplaca`,
    nullContractReport: `${BASE_URL}/operacionesanuladas/reporte`,
    postDeliveryBeginsTerm: `${BASE_URL}/entregaactivos/activo`,
    getAssets: `${BASE_URL}/consultaoperacion/activospornumerocontrato`,
    getRisksAsset: `${BASE_URL}/riesgoactivo/obtenertodos`,
    getOperationByco: `${BASE_URL}/consultaoperacion/opxcontranto`,
    getOperationBtDoc: `${BASE_URL}/consultaoperacion/opxcliente`,
    getCuentaCobroBtDoc: `${BASE_URL}/consultaoperacion/opxcliente`,
    getOperationMoreData: `${BASE_URL}/consultaoperacion/masdatos`,
    getAnexes: `${BASE_URL2}/type-document/`,
    getPolicyByContract: `${BASE_URL}/polizavalidacion/porcontrato`,
    getResidualConsult: `${BASE_URL}/consultaoperacion/consultaresiduales`,
    getStatusContract: `${BASE_URL}/estadosoperacion/listartodo`,
    getCharacteristicsOperation: `${BASE_URL}/caracteristicas/obtener-por-operacion`,
  },
  actOfApproval: {
    getApprovalValues: `${BASE_URL}/actadeaprobacion/valoresaprobacion`,
    getObservations: `${BASE_URL}/actadeaprobacion/observaciones`,
    getInformation: `${BASE_URL}/actadeaprobacion/informacion`,
    getEconomicGroup: `${BASE_URL}/actadeaprobacion/grupoeconomico`,
    getWarranty: `${BASE_URL}/actadeaprobacion/garantias`,
    getValueDetails: `${BASE_URL}/actadeaprobacion/detallevalores`,
    getDestiny: `${BASE_URL}/actadeaprobacion/destinos`,
    getAllDestinyWithoutPag: `${BASE_URL}/actadeaprobacion/destinosnopaginado`,
  },
  users: {
    reports: `${BASE_URL}/reporteperfiles/excel`,
    create: `${BASE_URL}/usuarios/guardar`,
    createRole: `${BASE_URL}/roles/guardarrol`,
    screens: `${BASE_URL}/roles/obtenerpantallas`,
    getRoleOwner: `${BASE_URL}/usuarios/obtenerdueniosrol`,
    getRoles: `${BASE_URL}/roles/obtenertodo`,
    saveRole: `${BASE_URL}/roles/guardarrol`,
    updateRole: `${BASE_URL}/roles/actualizarrol`,
    reportCsv: `${BASE_URL}/reporteperfiles/csv`,
    getUsers: `${BASE_URL}/usuarios/obtenerperfilesdueniorol`,
    deleteProfile: `${BASE_URL}/usuarios/eliminarperfilusuariogruposeguridad`,
    updateProfile: `${BASE_URL}/usuarios/asignarperfilesusuariogruposeguridad`,
    getScreenDisplay: `${BASE_URL}/roles/obtenerpantallasimagen`,
    disabledRole: `${BASE_URL}/roles/inactivarrol`,
    getProfilesByFilter: `${BASE_URL}/roles/obteneractivosfiltrado`,
    getListRol: `${BASE_URL}/roles/obtener_roles_por_duenio`,
    createRolesByGroup: `${BASE_URL}/gruposeguridadroles/asociargruposeguridadxrol`,
    getRolesBySecurityGroup: `${BASE_URL}/gruposeguridadroles/obtenerrolesxgruposeguridad`,
    updateRolesByGroup: `${BASE_URL}/gruposeguridadroles/modificargruposeguridadxrol`,
    accountingReport: `${BASE_URL}/contabilidad/reporte`,
    accessControlReport: `${BASE_URL}/auditoria/reporte`,
    getInfoUsers: `${BASE_URL}/roles/obtener_rol_por_nombre`,
  },
  rates: {
    getAllPeriodicity: `${BASE_URL}/periodicidad/buscartodos`,
    getAllFormulas: `${BASE_URL}/formulareajuste/consultaractivos`,
    getRatesCodName: `${BASE_URL}/tasas/codigostasas`,
    getAllRatesPage: `${BASE_URL}/tasasparametrizadas/consultarpaginado`,
    saveRate: `${BASE_URL}/tasasparametrizadas/guardar`,
    updateRate: `${BASE_URL}/tasasparametrizadas/actualizar`,
  },
  formula: {
    getAllFormulas: `${BASE_URL}/formulareajuste/consultarvariables`,
    saveFormula: `${BASE_URL}/formulareajuste/guardar`,
    getAllFormulasPage: `${BASE_URL}/formulareajuste/consultarpaginado`,
    updateFormula: `${BASE_URL}/formulareajuste/actualizar`,
  },
  operationType: {
    saveOperationType: `${BASE_URL}/tipooperacion/guardar`,
    updateOperationType: `${BASE_URL}/tipooperacion/actualizar`,
    getAllOperationTypes: `${BASE_URL}/tipooperacion/obtenertodos`,
    getActiveOperationTypes: `${BASE_URL}/tipooperacion/buscarfiltroactivos`,
  },
  offersManagement: {
    saveCreationMasterOffer: `${BASE_URL}/ofertamaster/guardar`,
    getOfferByDocNumber: `${BASE_URL}/ofertamaster/obtenertodos`,
    getOperationsByOfferCode: `${BASE_URL}/creacionoperacionrenta/operacionesporofertamaster`,
    inactivateOfferByCode: `${BASE_URL}/ofertamaster/inactivar`,
    getOffersActiveByDocNumber: `${BASE_URL}/ofertamaster/obteneractivos`,
  },
  canons: {
    getCanons: `${BASE_URL}/plancanones/consultar`,
    getCanonDetails: `${BASE_URL}/plancanones/detalle`,
    getPaymentDetails: `${BASE_URL}/detallepagos/consultar`,
  },
  calendar: {
    getHolidaysByYear: `${BASE_URL}/diasfestivos/consultarporanio`,
    setHolidaysByYear: `${BASE_URL}/diasfestivos/guardar`,
    getWeeksByYear: `${BASE_URL3}/semanas/consultar`,
  },
  warranties: {
    getWarranties: `${BASE_URL}/garantiasoperacion/consultapordocumentocontrato`,
    asociateWarranties: `${BASE_URL}/creacionoperacionrenta/asociargarantias`,
    getRentOperationsFilter: `${BASE_URL}/creacionoperacionrenta/filtrardocumentocontrato`,
    getWarrantiesConditions: `${BASE_URL}/garantiasoperacion/consultacondiciones`,
    getAllNullReasonsPaginated: `${BASE_URL}/motivoanulaciongarantias/obtenertodos`,
    createReasonNullWarrantyParam: `${BASE_URL}/motivoanulaciongarantias/guardar`,
    updateNullReasonParameter: `${BASE_URL}/motivoanulaciongarantias/editar`,
    getAllNullReasonsNonPaginated: `${BASE_URL}/motivoanulaciongarantias/listaractivos`,
    sendWarrantiesToCancel: `${BASE_URL}/garantiasoperacion/anulaciongarantias`,
    getOnlyActiveGuarantees: `${BASE_URL}/garantiasoperacion/consultaporcontratocreadas`,
  },
  ventas: {
    saveSales: `${BASE_URL}/ventas/guardarventas`,
    saveSalesMassive: `${BASE_URL}/ventas/guardarventasmasivo`,
    getSalesHistory: `${BASE_URL}/ventas/consultarventaspaginado`,
    getSalesHistoryFile: `${BASE_URL}/ventas/consultarhistoricoventas`,
    getExcelCausesBySaleId: `${BASE_URL}/ventas/excelcausales`,
    getCausesBySaleId: `${BASE_URL}/ventas/consultarventascausales`,
  },
  gastos: {
    getExpensesType: `${BASE_URL}/gasto/buscarporfiltros`,
    saveExpenses: `${BASE_URL}/gastosporoperacion/guardar`,
    createExpensesParam: `${BASE_URL}/gasto/guardar`,
    getAllExpenseParameters: `${BASE_URL}/gasto/obtenertodos`,
    updateExpensesParam: `${BASE_URL}/gasto/actualizar`,
    getExpensesPag: `${BASE_URL}/gastosporoperacion/paginadopornumerocontrato`,
    sendExpensesToCancel: `${BASE_URL}/gastosporoperacion/anular`,
    getVatPercentageByAssetType: `${BASE_URL}/porcentajeiva/consultar`,
    getCustomerConditions: `${BASE_URL}/infoclientes/obtenerimpuestos`,
  },
  accountingAccounts: {
    getAccountsById: `${BASE_URL}/cuentascontables/consultarcuentascontables`,
    getWalletClass: `${BASE_URL}/clasecartera/obtenerclasecarteras`,
    getQualificationType: `${BASE_URL}/tipocalificacion/obtenertipocalificaciones`,
    getMasterAccountingAccounts: `${BASE_URL}/planunicocuentas/buscarporfiltros`,
    getMasterAccountsByFilters: `${BASE_URL}/maestrocuentas/buscar`,
    createSingleAccountPlan: `${BASE_URL}/planunicocuentas/guardar`,
    getAllAccountParameters: `${BASE_URL}/planunicocuentas/obtenertodos`,
    updateAccountParameter: `${BASE_URL}/planunicocuentas/modificar`,
    getNitAccountingAccounts: `${BASE_URL}/cuentascontablesnit/obtenercuentascontablesnit`,
    getAccountingAccountsDocument: `${BASE_URL}/cuentascontablesdocumento/obtenercuentascontablesdocumento`,
    createAccountingAccountsConfig: `${BASE_URL}/cuentascontables/guardar`,
  },
  reasonForCancellation: {
    getPagCancelReasons: `${BASE_URL}/motivoanulacioncxc/obtenertodos`,
  },
  reasonCancellation: {
    getReason: `${BASE_URL}/motivoanulacioncxc/obtenertodos`,
    saveReason: `${BASE_URL}/motivoanulacioncxc/guardar`,
    updateReason: `${BASE_URL}/motivoanulacioncxc/editar`,
  },
  delivery: {
    getDelSolu: `${BASE_URL}/reportecanonsolucion/consultar`,
    getExcelSolu: `${BASE_URL}/reportecanonsolucion/excel`,
  },
  wallet: {
    getConsultWallet: `${BASE_URL}/consultaoperacion/consultacartera`,
  },
  consultIfrs: {
    getDataIfrs: `${BASE_URL}/evaluacionifrs/consultar-evaluaciones`,
    getExcelIfrs: `${BASE_URL}/evaluacionifrs/generar-excel`,
  },
  reportOperation: {
    getExcel: `${BASE_URL3}/reporteopcreadas/consultarexcel`,
    getStatusOperation: `${BASE_URL3}/reporterianegocio/estadosoperacion`,
  },
  insurance: {
    getPolicyByNumber: `${BASE_URL}/polizavalidacion/consultar`,
    getPoliciesByContract: `${BASE_URL}/polizavalidacion/porcontrato`,
    savePoliciesFile: `${BASE_URL}/polizas/guardar`,
    getChargePolicies: `${BASE_URL}/polizas/consultarcargue`,
    getPolicyCausesById: `${BASE_URL}/polizas/consultarpolizascausales`,
  },
  eventAccountant: {
    getFountain: `${BASE_URL}/eventoscontables/fuentes`,
    eventByFountain: `${BASE_URL}/parametrizarevc/fuentes`,
    eventConfiguration: `${BASE_URL}/parametrizarevc/configuracion`,
    getListMap: `${BASE_URL}/eventoscontables/obtenertodos`,
    updateEvent: `${BASE_URL}/eventoscontables/modificar`,
    createEvent: `${BASE_URL}/eventoscontables/guardar`,
  },
  moreData: {
    getMoreData: `${BASE_URL}/consultaoperacion/consultaactivosxop`,
  },
  use: {
    getOperationsByDocument: `${BASE_URL}/consultaoperacion/consultaoppreautorizado`,
    getAccountCollectionByNit: `${BASE_URL3}/generadordocumento/obtenerdocumentocuentacobro`,
    getTypesCollectionAccount: `${BASE_URL3}/tipodecuenta/consultar`,
    getAccountCollectionByIndiv: `${BASE_URL3}/cuentacobro/pdf`,
  },
  preautorized: {
    getTerceros: `${BASE_URL}/tercerosnit/consultar`,
    getBank: `${BASE_URL}/bancosach/consultar`,
    getAccount: `${BASE_URL}/tipocuenta/consultar`,
    savePreautorized: `${BASE_URL}/preautorizados/guardar`,
    consultPreautorized: `${BASE_URL3}/historicodebitoautomatico/consultar`,
    consultHistoricoDebitoAutomatico: `${BASE_URL3}/historicodebitoautomatico/consultarbyoperacion`,
  },
};
