import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from './core/guards/auth.guard';
import { MasterComponent } from './modules/master/master.component';

export const loadChildrenMasterSubscriber = (m: any) => m.MasterModule;
export const loadChildrenMaster = () =>
  import('./modules/master/master.module').then(loadChildrenMasterSubscriber);
export const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./modules/autenticacion/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: MasterComponent,
    loadChildren: loadChildrenMaster,
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
