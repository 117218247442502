import { Injectable } from '@angular/core';
import { ApiHelperService } from '@services/apihelper/api-helper.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TokenService } from '../token-JWT/token.service';
import { TokenDataDetails } from '@models/TokenData';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private dataSubject: BehaviorSubject<any[]> = new BehaviorSubject([{}]);
  private data$: Observable<any[]> = this.dataSubject.asObservable();
  constructor(private http: ApiHelperService, private tokenService: TokenService) {}

  refreshRequest(): Observable<TokenDataDetails> {
    return this.http.get('arrendamientodigback/rentas/login/refrescar');
  }
  /**
   * loginRequest
   * send by POST method the msal token to get our app scope token data
   */
  loginRequest(): Observable<TokenDataDetails> {
    return this.http.post('arrendamientodigback/rentas/login', {}, '', {});
    // return of({ login: response }) as Observable<TokenData>;
  }

  /**
   * storeToken
   * save our app token data from the login
   */
  storeToken(tokenData: TokenDataDetails): Observable<boolean> {
    if (tokenData?.token != null && tokenData?.token?.length != 0) {
      return of(true);
    } else {
      return of(false);
    }
  }

  getData(): Observable<any[]> {
    return this.data$;
  }

  updateData(pantallas: any[]): void {
    this.dataSubject.next(pantallas);
  }
}
