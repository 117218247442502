import { Injectable } from '@angular/core';
import {
  ApprovalValues,
  DestinosNoPaginado,
  Destiny,
  EconomicGroup,
  Information,
  Observation,
  ValueDetails,
  Warranty,
} from 'src/app/core/models/ActOfApproval';
import { ApiHelperService } from '@services/apihelper/api-helper.service';
import { Observable } from 'rxjs';
import { backend } from 'src/paths/development';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActOfApprovalService {
  constructor(private apiHelper: ApiHelperService) {}

  /**
   * approvalValues returns an array of approvalValues model
   * @param {
   *  approvalNumber: string
   * }
   * @returns Observable<ApprovalValues>
   */
  getApprovalValues({
    approvalNumber,
    page,
    size,
  }: {
    [key: string]: any;
  }): Observable<ApprovalValues> {
    return this.apiHelper
      .get(
        `${backend.actOfApproval.getApprovalValues}?numeroAprobacion=${approvalNumber}&page=${page}&size=${size}`
      )
      .pipe(map(this.approvalValuesMapper));
  }

  approvalValuesMapper = (response: ApprovalValues) => response;

  /**
   * getObservations of an act of approval
   * @param {
   *  approvalNumber: string
   * }
   * @returns Observable<Observations>
   */
  getObservations({
    approvalNumber,
  }: {
    [key: string]: any;
  }): Observable<Observation> {
    return this.apiHelper
      .get(
        `${backend.actOfApproval.getObservations}?numeroAprobacion=${approvalNumber}`
      )
      .pipe(map(this.observationsMapper));
  }

  observationsMapper = (response: any) => response.observacionesActaAprobacion;

  /**
   * getInfActApproval get the act of approval information
   * @param {
   *  approvalNumber: string
   * }
   * @returns Observable<Information>
   */
  getInfActApproval({
    approvalNumber,
  }: {
    [key: string]: any;
  }): Observable<Information> {
    return this.apiHelper
      .get(
        `${backend.actOfApproval.getInformation}?numeroAprobacion=${approvalNumber}`
      )
      .pipe(map(this.infActApprovalMapper));
  }
  infActApprovalMapper = (response: any) => response.actaAprobacion;

  /**
   * getEconomicGroup get the act of approval economic group
   * @param {
   *  documentClient: string
   * }
   * @returns Observable<EconomicGroup>
   */
  getEconomicGroup({
    documentClient,
  }: {
    [key: string]: any;
  }): Observable<EconomicGroup> {
    return this.apiHelper
      .get(
        `${backend.actOfApproval.getEconomicGroup}?documentoCliente=${documentClient}`
      )
      .pipe(map(this.economicGroupMapper));
  }

  economicGroupMapper = (response: any) => response.grupoEconomico;

  /**
   * getWaranty get the act of approval warranty
   * @param {
   *  approvalNumber: string
   * }
   * @returns Observable<Warranty>
   */
  getWaranty({
    approvalNumber,
    page,
    size,
  }: {
    [key: string]: any;
  }): Observable<Warranty> {
    return this.apiHelper
      .get(
        `${backend.actOfApproval.getWarranty}?numeroAprobacion=${approvalNumber}&page=${page}&size=${size}`
      )
      .pipe(map(this.warrantyMapper));
  }

  warrantyMapper = (response: Warranty) => response;

  /**
   * geValueDetails get the act of approval ValueDetails
   * @param {
   *  approvalNumber: string
   * }
   * @returns Observable<ValueDetails>
   */
  getValueDetails({
    approvalNumber,
    numOperacion,
    numFactura,
    page,
    size,
  }: {
    [key: string]: any;
  }): Observable<ValueDetails> {
    return this.apiHelper
      .get(
        `${backend.actOfApproval.getValueDetails}?numeroAprobacion=${approvalNumber}&numOperacion=${numOperacion}&numFactura=${numFactura}&page=${page}&size=${size}`
      )
      .pipe(map(this.valueDetailsMapper));
  }

  valueDetailsMapper = (response: ValueDetails) => response;

  /**
   * getDestiny get the act of approval Destiny
   * @param {
   *  approvalNumber: string
   * page: number,
   * size: number,
   * }
   * @returns Observable<Destiny>
   */
  getDestiny({
    approvalNumber,
    page,
    size,
  }: {
    [key: string]: any;
  }): Observable<Destiny> {
    return this.apiHelper
      .get(
        `${backend.actOfApproval.getDestiny}?numeroAprobacion=${approvalNumber}&page=${page}&size=${size}`
      )
      .pipe(map(this.destinyMapper));
  }

  destinyMapper = (response: Destiny) => response;

  /**
   * Get all getAllDestinyWithoutPag
   * @returns Observable<DestinosNoPaginado>
   */
  getAllDestinyWithoutPag(
    numeroAprobacion: string
  ): Observable<DestinosNoPaginado> {
    return this.apiHelper
      .get(
        `${backend.actOfApproval.getAllDestinyWithoutPag}?numeroAprobacion=${numeroAprobacion}`
      )
      .pipe(map((response: DestinosNoPaginado) => response));
  }
}
