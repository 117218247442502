import { Numbers } from '@constants/numbers';
import { IValueItemSelectBox } from '@models/ISharedResponse';

export const monthsArray = Array.from({ length: Numbers._31 }, (_, index) => ({
  id: index + Numbers._1,
  name: (index + Numbers._1).toString(),
}));

export const ACTIVE_OR_INACTIVE_OPTIONS: IValueItemSelectBox[] = [
  {
    label: 'Activo',
    value: { value: true },
  },
  {
    label: 'Inactivo',
    value: { value: false },
  },
];

export const DAYS_BASE_OPTIONS: IValueItemSelectBox[] = [
  {
    label: 'Base 360 días',
    value: {
      value: Numbers._360,
    },
  },
  {
    label: 'Base 365 días',
    value: {
      value: Numbers._365,
    },
  },
];

export const FEE_COLLECTION_OPTIONS: IValueItemSelectBox[] = [
  {
    label: 'Vencido',
    value: {
      value: 'Vencido',
    },
  },
  {
    label: 'Anticipado',
    value: {
      value: 'Anticipado',
    },
  },
];

export const YES_OR_NOT_OPTIONS: IValueItemSelectBox[] = [
  {
    label: 'Si',
    value: {
      value: true,
    },
  },
  {
    label: 'No',
    value: {
      value: false,
    },
  },
];

export const DAYS_OF_MONTH_OPTIONS: IValueItemSelectBox[] = Array.from(
  { length: Numbers._31 },
  (_, index) => ({
    label: `${index + Numbers._1}`,
    value: {
      value: index + Numbers._1,
    },
  })
);
