import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IParametersTypeOperation,
  IResponseCharacCreateUpdate,
} from 'src/app/core/models/Caracteristicas';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelperService } from '@services/apihelper/api-helper.service';
import { backend } from 'src/paths/development';
import { Numbers } from '../../core/constants/numbers';
import { CaracteristicasList } from '@models/Caracteristicas';
import { ICreateUpdateParam } from '@models/ISharedResponse';

@Injectable({ providedIn: 'root' })
export class OperationService {
  constructor(private http: ApiHelperService) {}

  /**
   * Get an array of operation
   * @param page: Number of the page to show in a view
   * @param size: Size of elements to show in a view
   * @returns Observable<Operation[]>
   */
  getAllOperations(
    page?: number,
    size?: number | '',
    filtro?: string,
    estado?: boolean
  ): Observable<IParametersTypeOperation> {
    const pageRequest = page ?? Numbers._0;
    const sizeRequest = size ?? Numbers._5;
    const queryParams = `?page=${pageRequest}&size=${sizeRequest}&filtro=${filtro}&estado=${estado}`;
    return this.http.get(`${backend.operation.getAll}${queryParams}`).pipe(
      map((response: any) => response),
      map((response) => response as IParametersTypeOperation)
    );
  }

  /**
   * Crear parametro
   * @param body: ICreateUpdateParam
   * @returns Observable<IResponseCharacCreateUpdate>
   * Ope = operacion
   */
  createCharacteristicsOpe({
    body,
  }: {
    body: ICreateUpdateParam;
  }): Observable<IResponseCharacCreateUpdate> {
    return this.http.post(`${backend.operation.create}`, body);
  }

  /**
   * Actualizar parametro
   * @param body: ICreateUpdateParam
   * @returns Observable<IResponseCharacCreateUpdate>
   * Ope = operacion
   */
  updateCharacteristicsOpe({
    body,
  }: {
    body: ICreateUpdateParam;
  }): Observable<IResponseCharacCreateUpdate> {
    return this.http.put(`${backend.operation.update}`, body);
  }

  /**
   * Search an existent operation by operation code
   * @param code: A code operation to search
   * @returns Observable<CaracteristicasList>
   */
  searchByCoodeOperation(code: string): Observable<CaracteristicasList> {
    return this.http
      .get(`${backend.operation.getByCode}/${code}`)
      .pipe(map((res: any) => res as CaracteristicasList));
  }

  /**
   * Deactivate an existent operation by operation code
   * @param code: A code operation to deactivate
   * @returns Observable<number>
   */
  deactivateOperation(code: string): Observable<number> {
    return this.http
      .put<any>(`${backend.operation.deactivate}/${code}`, {}, undefined, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.status));
  }

  /**
   * Activate an existent operation by operation code
   * @param code: A code operation to activate
   * @returns Observable<number>
   */
  activateOperation(code: string): Observable<number> {
    return this.http
      .put<any>(`${backend.operation.activate}/${code}`, {}, undefined, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.status));
  }
}
