import { InjectionToken } from '@angular/core';
import { LoginReducer, LoginState } from './reducers';
import { Action, ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

interface State {
  login: LoginState;
}
export const factoryFunction = () => ({
  login: LoginReducer.Reducer,
});

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>(
  'Root reducers token',
  {
    factory: factoryFunction,
  }
);

const getLoginState = createFeatureSelector<LoginState>('login');

const getToken = createSelector(getLoginState, LoginReducer.getToken);
const getPantallas = createSelector(getLoginState, LoginReducer.getPantallas);
const getError = createSelector(getLoginState, LoginReducer.getError);
const getRefresh = createSelector(getLoginState, LoginReducer.getRefresh);

export const fromLogin = {
  getToken,
  getPantallas,
  getError,
  getRefresh,
};

export { State };
