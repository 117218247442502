<ng-container *ngIf="hiddenModules">
  <app-header></app-header>

  <bc-menu class="menu-app-showcase" state="close" itemActive="id1">
    <bc-menu-drawer>
      <bc-menu-item
        [ngClass]="{ 'card-disabled': disabledMenuItem }"
        [routerLink]="['/app']"
        identifierContent="id1"
        text="Inicio"
        (click)="changePage('')"
        iconName="home"
        itemActive="true"
      ></bc-menu-item>
      <ng-container *ngIf="adminModule">
        <bc-menu-item
          [ngClass]="{ 'card-disabled': disabledMenuItem }"
          [routerLink]="['/app/administracion']"
          identifierContent="id2"
          text="Administración"
          iconName="table-settings"
          (click)="changePage(ADMIN_MODULE_PARAM)"
        ></bc-menu-item>
      </ng-container>
      <ng-container *ngIf="operacionRenta">
        <bc-menu-item
          [ngClass]="{ 'card-disabled': disabledMenuItem }"
          [routerLink]="['/app/operacion-renta']"
          identifierContent="id3"
          (click)="changePage(OPRENT_MODULE_PARAM)"
          text="Operación Renta"
          iconName="edit-document"
        ></bc-menu-item>
      </ng-container>

      <ng-container *ngIf="reportsModule">
        <bc-menu-item
          [ngClass]="{ 'card-disabled': disabledMenuItem }"
          [routerLink]="['/app/reportes']"
          identifierContent="id4"
          (click)="changePage(REPORT_MODULE_PARAM)"
          text="Reportes"
          iconName="approving-user"
        ></bc-menu-item>
      </ng-container>
      <ng-container>
        <div class="log-out bc-menu-submenu btn-personalized">
          <div class="log-out-items bc-submenu-footer-container">
            <button class="log-out-btn bc-fab-button bc-fab-button-mini" (click)="logOut()">
              <bc-icon class="bc-icon bc-sm">quit</bc-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </bc-menu-drawer>

    <bc-menu-main></bc-menu-main>

    <div class="bc-content-body bcs-container-body">
      <div class="bc-content-router">
        <div class="bc-d-flex bc-justify-content-between bc-align-items-center bc-mb-3">
          <span>{{ title }}</span>
          <p class="m-0 status">{{ date }} {{ currentDateTime }}</p>
        </div>
        <div>
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </bc-menu>

  <app-bcs-modal
    #modalRefreshToken
    title="Alerta"
    customWidth="medium"
    textBtnLeft="No"
    textBtn="Si"
    [outerCloseTap]="false"
    [hideCloseButton]="true"
    (stateClick)="checkTokenAction($event)"
  >
    <div class="bc-d-flex bc-justify-content-center bc-mb-4">
      <div class="countdown-spinner">
        <div class="spinner"></div>
        <div class="countdown-text">{{ countdownConfirmation }}</div>
      </div>
    </div>

    <span>{{ messageModalRefresh }}</span>
    <h6 class="bc-mt-2">¿Desea continuar?</h6>
  </app-bcs-modal>
</ng-container>

<app-bcs-modal
  #modalErrorHelper
  customWidth="medium"
  title="Error de conexión"
  textBtn="Cerrar"
  typeIcon="error"
  [hideCloseButton]="true"
  [outerCloseTap]="false"
  [isCancellable]="false"
  (stateClick)="handleErrorRequestHelper($event)"
>
  <section class="bc-d-flex bc-justify-content-center bc-align-items-center">
    <p class="bcs-font-size-16px" [innerHTML]="errorMessage"></p>
  </section>
</app-bcs-modal>
