import { Injectable } from '@angular/core';
import {
  ApprovalValues,
  Destiny,
  EconomicGroup,
  Information,
  Observation,
  ValueDetails,
  Warranty,
} from '@models/ActOfApproval';
import { IParametersTypeOperation } from '@models/Caracteristicas';
import { ILegalFile } from '@models/ILegalFile.interface';
import { ILegalRepresentative } from '@models/ILegalRepresentative.interface';
import { ILoadData } from '@models/ILoadData';
import { IResponsibleBusiness } from '@models/IResponsibleBusiness.interfaces';
import { ISelectBox } from '@models/ISelectBox';
import { ProductosList } from '@models/Product';
import {
  ActiveApproves,
  Arrendatarios,
  ClientInformation,
  ICodeMora,
  IListProjectName,
  IRates,
  ListActiveMarkPage,
  ListUnitsPage,
  RentOperation,
} from '@models/RentOperation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActOfApprovalService } from '@services/act-of-approval/act-of-approval.service';
import { OperationService } from '@services/operation/operation.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RentOperationService } from '../../../../services/rent-operation/rent-operation.service';
import { RentOperationAction } from './actions';
import { AssetTypeService } from '@services/asset-types/asset-type.service';
import { DataColumnService } from '@services/data-column/data-column.service';

@Injectable()
export class RentOperationEffects {
  constructor(
    private rentOperationService: RentOperationService,
    private actOfApprovalService: ActOfApprovalService,
    private operationService: OperationService,
    private actions$: Actions,
    private assetTypeService: AssetTypeService,
    private dataColumnService: DataColumnService
  ) {
    // This is intentional
  }

  /**  postCustomerByFilterEffect$ **/
  customerFilterSuccessMapper = (customersList: ClientInformation[]) =>
    RentOperationAction.postCustomerByFilterSuccess({ customersList });

  customerFilterSuccessErrorMapper = (error: any) => of(RentOperationAction.onError(error));

  postCustomerByFilterEffectSwitchMapFunction = ({
    name,
    docClient,
  }: {
    name?: string;
    docClient?: string;
  }) =>
    this.rentOperationService
      .findClientInformation({ name, docClient })
      .pipe(
        map(this.customerFilterSuccessMapper),
        catchError(this.customerFilterSuccessErrorMapper)
      );

  postCustomerByFilterEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.postCustomerByFilter),
      switchMap(this.postCustomerByFilterEffectSwitchMapFunction)
    );

  postCustomerByFilterEffect$ = createEffect(this.postCustomerByFilterEffectFunction);
  /**  postCustomerByFilterEffect$ **/

  /** findApprovalValuesEffect$ **/
  /**
   * Dispach the function to consume the api from backend then store the response into the store
   */
  activeApprovesMapErrorFunction = (error: any) => of(RentOperationAction.onError(error));

  activeApprovesMapFunction = (approvalValuesList: ActiveApproves) =>
    RentOperationAction.setApprovalValuesList({ approvalValuesList });

  findApprovalValuesEffectSwitchMapFunction = ({
    docClient,
    page,
    size,
  }: {
    docClient: string;
    page: number;
    size: number;
  }) =>
    this.rentOperationService
      .activeApproves({ docClient, page, size })
      .pipe(map(this.activeApprovesMapFunction), catchError(this.activeApprovesMapErrorFunction));

  findApprovalValuesEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findApprovalValuesList),
      switchMap(this.findApprovalValuesEffectSwitchMapFunction)
    );

  findApprovalValuesEffect$ = createEffect(this.findApprovalValuesEffectFunction);
  /** findApprovalValuesEffect$ **/

  /** findRentOperationValuesEffect **/
  findRentOperationValuesEffectErrorFunction = (error: any) =>
    of(RentOperationAction.onError(error));
  findRentOperationValuesEffectMapFunction = (rentOperationValues: RentOperation) =>
    RentOperationAction.setRentOperationValues({
      rentOperationValues,
    });

  findRentOperationValuesEffectSwitchMapFunction = ({ approvalValue }: { approvalValue: string }) =>
    this.rentOperationService
      .rentOperationModule({ approvalValue })
      .pipe(
        map(this.findRentOperationValuesEffectMapFunction),
        catchError(this.findRentOperationValuesEffectErrorFunction)
      );
  findRentOperationValuesEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findRentOperationValues),
      switchMap(this.findRentOperationValuesEffectSwitchMapFunction)
    );
  findRentOperationValuesEffect$ = createEffect(this.findRentOperationValuesEffectFunction);
  /** findRentOperationValuesEffect **/

  /** findProjectNameListEffect **/
  findProjectNameListEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findProjectNameListEffectMapFunction = (projectNameList: IListProjectName) =>
    RentOperationAction.setProjectNameList({
      projectNameList,
    });

  findProjectNameListEffectSwitchMapFunction = () =>
    this.rentOperationService
      .getProjectList()
      .pipe(
        map(this.findProjectNameListEffectMapFunction),
        catchError(this.findProjectNameListEffectErrorFunction)
      );
  findProjectNameListValuesEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findProjectNameList),
      switchMap(this.findProjectNameListEffectSwitchMapFunction)
    );
  findProjectNameListEffect$ = createEffect(this.findProjectNameListValuesEffectFunction);
  /** findProjectNameListEffect **/

  findLegalFileDataEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findLegalFileDataEffectMapFunction = (legalFile: ILegalFile | null | undefined) =>
    RentOperationAction.setLegalFileData({ legalFile });

  findLegalFileDataEffectSwitchMapFunction = ({ docNumber }: { docNumber: string }) =>
    this.rentOperationService
      .getLegalFileByDocClient(docNumber)
      .pipe(
        map(this.findLegalFileDataEffectMapFunction),
        catchError(this.findLegalFileDataEffectErrorFunction)
      );

  findLegalFileDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findLegalFileData),
      switchMap(this.findLegalFileDataEffectSwitchMapFunction)
    );
  findLegalFileDataEffect$ = createEffect(this.findLegalFileDataEffectFunction);

  /** findLegalRepresentaiveDataEffect **/
  findLegalRepresentativeDataEffectErrorFunction = (error: any) =>
    of(RentOperationAction.onError(error));
  findLegalRepresentativeDataEffectMapFunction = (legalRepresentative: ILegalRepresentative) =>
    RentOperationAction.setLegalRepresentativeData({
      legalRepresentative,
    });
  findLegalRepresentativeDataEffectSwitchMapFunction = ({
    docNumber,
    page,
    size,
  }: {
    docNumber: string;
    page: number;
    size: number;
  }) =>
    this.rentOperationService
      .getRepByDocClient({ docNumber, page, size })
      .pipe(
        map(this.findLegalRepresentativeDataEffectMapFunction),
        catchError(this.findLegalRepresentativeDataEffectErrorFunction)
      );
  findLegalRepresentaiveDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findLegalRepresentativeData),
      switchMap(this.findLegalRepresentativeDataEffectSwitchMapFunction)
    );
  findLegalRepresentaiveDataEffect$ = createEffect(this.findLegalRepresentaiveDataEffectFunction);
  /** findLegalRepresentaiveDataEffect **/

  /** findUnitsActiveListDataEffect **/
  findUnitsActiveListDataEffectErrorFunction = (error: any) =>
    of(RentOperationAction.onError(error));
  findUnitsActiveListDataEffectMapFunction = (unitsActiveList: ListUnitsPage) =>
    RentOperationAction.setUnitsActiveList({
      unitsActiveList,
    });
  findUnitsActiveListDataEffectSwitchMapFunction = ({
    nomProject,
    tipoActivo,
    page,
    size,
  }: {
    nomProject: string;
    tipoActivo: string;
    page: number;
    size: number;
  }) =>
    this.rentOperationService
      .getUnitsListPage({ nomProject, tipoActivo, page, size })
      .pipe(
        map(this.findUnitsActiveListDataEffectMapFunction),
        catchError(this.findUnitsActiveListDataEffectErrorFunction)
      );
  findUnitsActiveListDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findUnitsActiveList),
      switchMap(this.findUnitsActiveListDataEffectSwitchMapFunction)
    );
  findUnitsActiveListDataEffect$ = createEffect(this.findUnitsActiveListDataEffectFunction);
  /** findUnitsActiveListDataEffect **/

  /** findActivesMarkListDataEffect **/
  findActivesMarkDataEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findActivesMarkDataEffectMapFunction = (activesMarkList: ListActiveMarkPage) =>
    RentOperationAction.setActivesMarkList({
      activesMarkList,
    });
  findActivesMarkDataEffectSwitchMapFunction = ({
    codTipoActivo,
    codGrupo,
    codSubGrupo,
    activoMarca,
    page,
    size,
  }: {
    codTipoActivo: string;
    codGrupo: string;
    codSubGrupo: string;
    activoMarca: string;
    page: number;
    size: number;
  }) =>
    this.rentOperationService
      .getActiveMarkPage({ codTipoActivo, codGrupo, codSubGrupo, activoMarca, page, size })
      .pipe(
        map(this.findActivesMarkDataEffectMapFunction),
        catchError(this.findActivesMarkDataEffectErrorFunction)
      );
  findActivesMarkDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findActivesMarkList),
      switchMap(this.findActivesMarkDataEffectSwitchMapFunction)
    );
  findActivesMarkDataEffect$ = createEffect(this.findActivesMarkDataEffectFunction);
  /** findActivesMarkListDataEffect **/

  /** findApprovalValuesDataEffect **/
  findApprovalValuesDataEffectErrorFunction = (error: any) =>
    of(RentOperationAction.onError(error));
  findApprovalValuesDataEffectMapFunction = (approvalValuesData: ApprovalValues) =>
    RentOperationAction.setApprovalValuesData({
      approvalValuesData,
    });
  findApprovalValuesDataEffectSwitchMapFunction = ({
    approvalNumber,
    page,
    size,
  }: {
    approvalNumber: string;
    page: number;
    size: number;
  }) =>
    this.actOfApprovalService
      .getApprovalValues({ approvalNumber, page, size })
      .pipe(
        map(this.findApprovalValuesDataEffectMapFunction),
        catchError(this.findApprovalValuesDataEffectErrorFunction)
      );
  findApprovalValuesDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findApprovalValuesData),
      switchMap(this.findApprovalValuesDataEffectSwitchMapFunction)
    );
  findApprovalValuesDataEffect$ = createEffect(this.findApprovalValuesDataEffectFunction);
  /** findApprovalValuesDataEffect **/

  /** findDetailValuesDataEffect **/
  findDetailValuesDataEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findDetailValuesDataEffectMapFunction = (detailValues: ValueDetails) =>
    RentOperationAction.setDetailValuesData({
      detailValues,
    });
  findDetailValuesDataEffectSwitchMapFunction = ({
    approvalNumber,
    numOperacion,
    numFactura,
    page,
    size,
  }: {
    approvalNumber: string;
    numOperacion: string | '';
    numFactura: string | '';
    page: number;
    size: number;
  }) =>
    this.actOfApprovalService
      .getValueDetails({ approvalNumber, numOperacion, numFactura, page, size })
      .pipe(
        map(this.findDetailValuesDataEffectMapFunction),
        catchError(this.findDetailValuesDataEffectErrorFunction)
      );
  findDetailValuesDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findDetailValuesData),
      switchMap(this.findDetailValuesDataEffectSwitchMapFunction)
    );
  findDetailValuesDataEffect$ = createEffect(this.findDetailValuesDataEffectFunction);
  /** findDetailValuesDataEffect **/

  /** findInfActApprovalDataEffect **/
  findInfActApprovalDataEffectErrorFunction = (error: any) =>
    of(RentOperationAction.onError(error));
  findInfActApprovalDataEffectMapFunction = (infActApproval: Information) =>
    RentOperationAction.setInfActApprovalData({ infActApproval });
  findInfActApprovalDataEffectSwitchMapFunction = ({
    approvalNumber,
  }: {
    approvalNumber: string;
  }) =>
    this.actOfApprovalService
      .getInfActApproval({ approvalNumber })
      .pipe(
        map(this.findInfActApprovalDataEffectMapFunction),
        catchError(this.findInfActApprovalDataEffectErrorFunction)
      );
  findInfActApprovalDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findInfActApprovalData),
      switchMap(this.findInfActApprovalDataEffectSwitchMapFunction)
    );
  findInfActApprovalDataEffect$ = createEffect(this.findInfActApprovalDataEffectFunction);
  /** findInfActApprovalDataEffect **/

  /** findDestinationsDataEffect **/
  findDestinationsDataEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findDestinationsDataEffectMapFunction = (destinations: Destiny) =>
    RentOperationAction.setDestinationData({ destinations });
  findDestinationsDataEffectSwitchMapFunction = ({
    approvalNumber,
    page,
    size,
  }: {
    approvalNumber: string;
    page: number;
    size: number | string;
  }) =>
    this.actOfApprovalService
      .getDestiny({ approvalNumber, page, size })
      .pipe(
        map(this.findDestinationsDataEffectMapFunction),
        catchError(this.findDestinationsDataEffectErrorFunction)
      );
  findDestinationsDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findDestinationData),
      switchMap(this.findDestinationsDataEffectSwitchMapFunction)
    );
  findDestinationsDataEffect$ = createEffect(this.findDestinationsDataEffectFunction);
  /** findDestinationsDataEffect **/

  /** findWarrantyDataEffect **/
  findWarrantyDataEffectMapFunction = (warranty: Warranty) =>
    RentOperationAction.setWarrantyData({ warranty });
  findWarrantyDataEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findWarrantyDataEffectSwitchMapFunction = ({
    approvalNumber,
    page,
    size,
  }: {
    approvalNumber: string;
    page: number;
    size: number;
  }) =>
    this.actOfApprovalService
      .getWaranty({ approvalNumber, page, size })
      .pipe(
        map(this.findWarrantyDataEffectMapFunction),
        catchError(this.findWarrantyDataEffectErrorFunction)
      );
  findWarrantyDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findWarrantyData),
      switchMap(this.findWarrantyDataEffectSwitchMapFunction)
    );
  findWarrantyDataEffect$ = createEffect(this.findWarrantyDataEffectFunction);
  /** findWarrantyDataEffect **/

  /** findObservationsDataEffect **/
  findObservationsDataEffectMapFunction = (observations: Observation) =>
    RentOperationAction.setObservationData({ observations });
  findObservationsDataEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findObservationsDataEffectSwitchMapFunction = ({ approvalNumber }: { approvalNumber: string }) =>
    this.actOfApprovalService
      .getObservations({ approvalNumber })
      .pipe(
        map(this.findObservationsDataEffectMapFunction),
        catchError(this.findObservationsDataEffectErrorFunction)
      );
  findObservationsDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findObservationData),
      switchMap(this.findObservationsDataEffectSwitchMapFunction)
    );
  findObservationsDataEffect$ = createEffect(this.findObservationsDataEffectFunction);
  /** findObservationsDataEffect **/

  /** findEconomicGroupDataEffect **/
  findEconomicGroupDataEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findEconomicGroupDataEffectMapFunction = (economicGroup: EconomicGroup) =>
    RentOperationAction.setEconomicGroupData({ economicGroup });
  findEconomicGroupDataEffectSwitchMapFunction = ({ docClient }: { docClient: string }) =>
    this.actOfApprovalService
      .getEconomicGroup({ docClient })
      .pipe(
        map(this.findEconomicGroupDataEffectMapFunction),
        catchError(this.findEconomicGroupDataEffectErrorFunction)
      );
  findEconomicGroupDataEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findEconomicGroupData),
      switchMap(this.findEconomicGroupDataEffectSwitchMapFunction)
    );
  findEconomicGroupDataEffect$ = createEffect(this.findEconomicGroupDataEffectFunction);
  /** findEconomicGroupDataEffect **/

  /** findProductsByFilter **/
  findProductsByFilterEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findProductsByFilterEffectMapFunction = (productsByFilterData: ProductosList[]) =>
    RentOperationAction.setProductsByFilter({ productsByFilterData });

  findProductsByFilterEffectSwitchMapFunction = ({ code, description }: { [key: string]: any }) =>
    this.rentOperationService
      .getProductsByFilter(code, description)
      .pipe(
        map(this.findProductsByFilterEffectMapFunction),
        catchError(this.findProductsByFilterEffectErrorFunction)
      );

  findProductsByFilterEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findProductsByFilter),
      switchMap(this.findProductsByFilterEffectSwitchMapFunction)
    );
  findProductsByFilterEffect$ = createEffect(this.findProductsByFilterEffectFunction);
  /** findProductsByFilter **/

  /** findRespBusiness **/
  findRespBusinessEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));

  findRespBusinessEffectMapFunction = (respBusiness: IResponsibleBusiness[]) =>
    RentOperationAction.setRespBusiness({ respBusiness });

  findRespBusinessEffectSwitchMapFunction = ({
    code,
    description,
  }: {
    code: string;
    description: string;
  }) =>
    this.rentOperationService
      .getRespBusinessByFilter(code, description)
      .pipe(
        map(this.findRespBusinessEffectMapFunction),
        catchError(this.findRespBusinessEffectErrorFunction)
      );

  findRespBusinessEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findRespBusiness),
      switchMap(this.findRespBusinessEffectSwitchMapFunction)
    );
  findRespBusinessEffect$ = createEffect(this.findRespBusinessEffectFunction);
  /** findRespBusiness **/

  /** findAccountManagerByFilterEffect */
  findAccountManagerByFilterErrorFunction = (error: any) =>
    of(RentOperationAction.onError({ error }));

  findAccountManagerByFilterEffectMapFunction = (accountManagerByFilter: ILoadData[]) =>
    RentOperationAction.setAccountManagerByFilter({ accountManagerByFilter });

  findAccountManagerByFilterEffectSwitchMapFunction = ({
    code,
    description,
  }: {
    code: string;
    description: string;
  }) =>
    this.rentOperationService
      .getAccountManagerByFilter(code, description)
      .pipe(
        map(this.findAccountManagerByFilterEffectMapFunction),
        catchError(this.findAccountManagerByFilterErrorFunction)
      );

  findAccountManagerByFilterEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findAccountManagerByFilter),
      switchMap(this.findAccountManagerByFilterEffectSwitchMapFunction)
    );
  findAccountManagerByFilterEffect$ = createEffect(this.findAccountManagerByFilterEffectFunction);
  /** findAccountManagerByFilterEffect */

  /** findAssetsByFilterEffect */
  findAssetsByFilterEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));
  findAssetsByFilterEffectMapFunction = (assetsByFilter: ILoadData[]) =>
    RentOperationAction.setAssetsByFilter({ assetsByFilter });
  findAssetsByFilterEffectSwitchMapFunction = ({
    code,
    description,
  }: {
    code: string;
    description: string;
  }) =>
    this.rentOperationService
      .getAssetsTypeByFilter(code, description)
      .pipe(
        map(this.findAssetsByFilterEffectMapFunction),
        catchError(this.findAssetsByFilterEffectErrorFunction)
      );
  findAssetsByFilterEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findAssetsByFilter),
      switchMap(this.findAssetsByFilterEffectSwitchMapFunction)
    );
  findAssetsByFilterEffect$ = createEffect(this.findAssetsByFilterEffectFunction);
  /** findAssetsByFilterEffect */

  /** findAssetsConditionEffect **/
  findAssetsConditionEffectMapFunction = (assetsCondition: ILoadData[]) =>
    RentOperationAction.setAssetsCondition({ assetsCondition });

  findAssetsConditionEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));

  findAssetsConditionEffectSwitchMapFunction = () =>
    this.rentOperationService
      .getAssetsCondition()
      .pipe(
        map(this.findAssetsConditionEffectMapFunction),
        catchError(this.findAssetsConditionEffectErrorFunction)
      );

  findAssetsConditionEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findAssetsCondition),
      switchMap(this.findAssetsConditionEffectSwitchMapFunction)
    );

  findAssetsConditionEffect$ = createEffect(this.findAssetsConditionEffectFunction);
  /** findAssetsConditionEffect **/

  /** findChannelsByFilterEffect **/
  findChannelsByFilterEffectMapFunction = (channelsByFilter: ILoadData[]) =>
    RentOperationAction.setChannelsByFilter({ channelsByFilter });

  findChannelsByFilterEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));

  findChannelsByFilterEffectSwitchMapFunction = ({
    code,
    description,
  }: {
    code: string;
    description: string;
  }) =>
    this.rentOperationService
      .getChannelsByFilter(code, description)
      .pipe(
        map(this.findChannelsByFilterEffectMapFunction),
        catchError(this.findChannelsByFilterEffectErrorFunction)
      );

  findChannelsByFilterEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findChannelsByFilter),
      switchMap(this.findChannelsByFilterEffectSwitchMapFunction)
    );

  findChannelsByFilterEffect$ = createEffect(this.findChannelsByFilterEffectFunction);
  /** findChannelsByFilterEffect **/

  /** findFundsByFilterEffect **/
  findFundsByFilterEffectMapFunction = (fundsByFilter: ILoadData[]) =>
    RentOperationAction.setFundsByFilter({ fundsByFilter });

  findFundsByFilterEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));

  findFundsByFilterEffectSwitchMapFunction = ({
    code,
    description,
  }: {
    code: string;
    description: string;
  }) =>
    this.rentOperationService
      .getFundsByFilter(code, description)
      .pipe(
        map(this.findFundsByFilterEffectMapFunction),
        catchError(this.findFundsByFilterEffectErrorFunction)
      );

  findFundsByFilterEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findFundsByFilter),
      switchMap(this.findFundsByFilterEffectSwitchMapFunction)
    );

  findFundsByFilterEffect$ = createEffect(this.findFundsByFilterEffectFunction);
  /** findFundsByFilterEffect **/

  /** findOperationCharacteristicEffect */
  findOperationCharacteristicEffectErrorFunction = (error: any) =>
    of(RentOperationAction.onError(error));

  findOperationCharacteristicEffectMapFunction = (
    operationCharacteristic: IParametersTypeOperation
  ) => RentOperationAction.setOperationCharacteristic({ operationCharacteristic });

  findOperationCharacteristicEffectSwitchMapFunction = ({
    page,
    size,
    filtro,
    estado,
  }: {
    page: number;
    size: number;
    filtro: string;
    estado: boolean;
  }) =>
    this.operationService
      .getAllOperations(page, size, filtro, estado)
      .pipe(
        map(this.findOperationCharacteristicEffectMapFunction),
        catchError(this.findOperationCharacteristicEffectErrorFunction)
      );

  findOperationCharacteristicEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findOperationCharacteristic),
      switchMap(this.findOperationCharacteristicEffectSwitchMapFunction)
    );

  findOperationCharacteristicEffect$ = createEffect(this.findOperationCharacteristicEffectFunction);
  /** findOperationCharacteristicEffect */

  /** findOperationTypeEffect **/
  findOperationTypeEffectMapFunction = (operationType: ISelectBox[]) =>
    RentOperationAction.setOperationType({ operationType });
  findOperationTypeEffectErrorFunction = (error: any) => of(RentOperationAction.onError({ error }));

  findOperationTypeEffectSwitchMapFunction = () =>
    this.rentOperationService
      .getAllOperationType()
      .pipe(
        map(this.findOperationTypeEffectMapFunction),
        catchError(this.findOperationTypeEffectErrorFunction)
      );

  findOperationTypeEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findOperationType),
      switchMap(this.findOperationTypeEffectSwitchMapFunction)
    );

  findOperationTypeEffect$ = createEffect(this.findOperationTypeEffectFunction);
  /** findOperationTypeEffect **/

  /** findRatesEffect **/
  findRatesEffectMapFunction = (ratesList: IRates[]) => RentOperationAction.setRates({ ratesList });
  findRatesEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));

  findRatesEffectSwitchMapFunction = () =>
    this.rentOperationService
      .getRatesData()
      .pipe(map(this.findRatesEffectMapFunction), catchError(this.findRatesEffectErrorFunction));

  findRatesEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findRates),
      switchMap(this.findRatesEffectSwitchMapFunction)
    );

  findRatesEffect$ = createEffect(this.findRatesEffectFunction);
  /** findRatesEffect **/

  /** findCodeMoraEffect **/
  findCodeMoraByFilterEffectMapFunction = (codeMoraByFilter: ICodeMora[]) =>
    RentOperationAction.setCodeMora({ codeMoraByFilter });

  findCodeMoraByFilterEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));

  findCodeMoraByFilterEffectSwitchMapFunction = ({
    code,
    description,
  }: {
    code: string;
    description: string;
  }) =>
    this.rentOperationService
      .getCodeMora({ code, description })
      .pipe(
        map(this.findCodeMoraByFilterEffectMapFunction),
        catchError(this.findCodeMoraByFilterEffectErrorFunction)
      );

  findCodeMoraByFilterEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findCodeMoraByFilter),
      switchMap(this.findCodeMoraByFilterEffectSwitchMapFunction)
    );

  findCodeMoraByFilterEffect$ = createEffect(this.findCodeMoraByFilterEffectFunction);
  /** findCodeMoraEffect **/

  /** findArrendatarioEffect **/
  findArrendatariosEffectMapFunction = (arrendatarios: Arrendatarios) =>
    RentOperationAction.setArrendatarioData({ arrendatarios });

  findArrendatariosEffectErrorFunction = (error: any) => of(RentOperationAction.onError(error));

  findArrendatarioSwitchMapFunction = ({
    noAprobation,
    noIdentification,
  }: {
    noAprobation: string;
    noIdentification: string;
  }) =>
    this.rentOperationService
      .arrendatarioModule({ noAprobation, noIdentification })
      .pipe(
        map(this.findArrendatariosEffectMapFunction),
        catchError(this.findArrendatariosEffectErrorFunction)
      );

  findArrendatariosEffectFunction = () =>
    this.actions$.pipe(
      ofType(RentOperationAction.findArrendatarioData),
      switchMap(this.findArrendatarioSwitchMapFunction)
    );

  findArrendatariosEffect$ = createEffect(this.findArrendatariosEffectFunction);
  /** findArrendatarioEffect **/

  /** findOpByContractNumberEffect **/
  findOpByContractNumberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RentOperationAction.findOpByContractNumber),
      switchMap(({ contractNumber }: { contractNumber: string }) =>
        this.rentOperationService.getOperatioByContract({ contractNumber }).pipe(
          map((opByContractNumber) =>
            RentOperationAction.setOpByContractNumberSuccess({
              opByContractNumber,
            })
          ),
          catchError((error) => of(RentOperationAction.onError(error)))
        )
      )
    )
  );
  /** findOpByContractNumberEffect **/

  /** findCurrentRatesEffect **/
  findCurrentRatesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RentOperationAction.findCurrentRates),
      switchMap(() =>
        this.rentOperationService.getCurrentRatesConsultation().pipe(
          map((currentRates) =>
            RentOperationAction.setCurrentRatesSuccess({
              currentRates,
            })
          ),
          catchError((error) => of(RentOperationAction.onError(error)))
        )
      )
    )
  );

  /** findExistentDataColumnffect **/
  findExistentDataColumnEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RentOperationAction.findExistentDataColumns),
      switchMap(({ codeActiveType }) =>
        this.assetTypeService.getColumnsByType(codeActiveType, true).pipe(
          map((existentDataColumn) =>
            RentOperationAction.setExistentDataColumn({
              existentDataColumn:
                this.dataColumnService.existentDataColumnMapper(existentDataColumn),
            })
          ),
          catchError((error) => of(RentOperationAction.onError(error)))
        )
      )
    )
  );
  /** findExistentDataColumnffect **/

  /** findNonExistentDataColumnffect **/
  findNonExistentDataColumnEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RentOperationAction.findNonExistentDataColumns),
      switchMap(({ codeActiveType }) =>
        this.assetTypeService.getColumnsByType(codeActiveType, false).pipe(
          map((nonExistentDataColumn) =>
            RentOperationAction.setNonExistentDataColumn({
              nonExistentDataColumn:
                this.dataColumnService.nonExistentDataColumnMapper(nonExistentDataColumn),
            })
          ),
          catchError((error) => of(RentOperationAction.onError(error)))
        )
      )
    )
  );
  /** findNonExistentDataColumnffect **/

  /** findActivesByPlatesffect **/
  findActivesByPlatesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RentOperationAction.findActivesByPlates),
      switchMap(({ monthTerm, plates }) =>
        this.rentOperationService.getActivesByPlates({ term: monthTerm, plates }).pipe(
          map((assets) => RentOperationAction.setExistentAssets({ assets })),
          catchError((error) => of(RentOperationAction.onError(error)))
        )
      )
    )
  );
  /** findActivesByPlatesffect **/
}
