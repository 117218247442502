import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IDataToRunFilters {
  assetTypeCode: string;
  termMonths: string;
}

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  public dataToRunFilters = new BehaviorSubject<any>(null); // Puedes cambiar el tipo según sea necesario
  dataToRunFiltersValues$ = this.dataToRunFilters.asObservable();

  public currentMenuTab = new BehaviorSubject<string | null>(null);
  currentMenuTabValues$ = this.currentMenuTab.asObservable();

  setDataToRunFilters(value: IDataToRunFilters): void {
    this.dataToRunFilters.next(value);
  }

  setCurrentMenuTab({ currentTab }: { currentTab: string }): void {
    this.currentMenuTab.next(currentTab);
  }

  resetCurrentMenuTab(): void {
    this.currentMenuTab.next(null);
  }
}
