import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Numbers } from '@constants/numbers';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private msalService: MsalService) {}

  isTokenValid(): Observable<{ show: boolean }> {
    return timer(Numbers._360000, Numbers._360000).pipe(
      map(this.isTokenValidMapFunction)
    );
  }

  isTokenValidMapFunction = () => ({ show: true });

  async getAccountActive(): Promise<any> {
    return this.msalService.instance.getActiveAccount();
  }
}
