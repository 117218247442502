//MODULO
export const ADMIN_MODULE = 'administrador';

//PANTALLA: AdministrationComponent
export const ADMIN_BTN_PARAM_CODE = 'btn-admin-params';
export const ADMIN_BTN_PROFILE_CODE = 'btn-admin-perf';

//PANTALLA: ParametersHomeComponent
export const BTN_ASSET_TYPE_PARAM = 'btn-assets-type-params';
export const BTN_PRODUCT_PARAM = 'btn-product-params';
export const BTN_CHANNELS_PARAM = 'btn-channels-params';
export const BTN_FUNDS_PARAM = 'btn-funds-params';
export const BTN_OP_CHARACT_PARAM = 'btn-op-charact-params';

//MODULO: SHARED ---> //PANTALLA: FormParamSupportDocComponent
export const ADMIN_BTN_PARAM_CREATE = 'btn-admin-params-crear';
export const ADMIN_BTN_PARAM_EDIT = 'btn-admin-params-edit';

//MODULO: RentOperationModule
export const OPRENT_MODULE = 'operacion_renta';

// MODULO: UserReportsModule
export const REPORTS_MODULE = 'reportes';

//PANTALLA: RentOperationComponent
export const OPRENT_BTN_CREATE = 'oper-rent-btn-create';
export const OPRENT_BTN_MODIFY = 'oper-rent-btn-modify';
export const OPRENT_BTN_QUERY = 'oper-rent-btn-query';
export const OPRENT_BTN_DELETE = 'oper-rent-btn-delete';

//PANTALLA: CreateRentOperationComponent
export const OPRENT_BTN_LEGAL_FILE = 'oper-rent-btn-legal-file';
export const OPRENT_BTN_ACT_APPROVAL = 'oper-rent-btn-act-approval';
export const OPRENT_BTN_CONTINUE = 'oper-rent-btn-continue';

//PANTALLA: LegalFileComponent
export const OPRENT_BTN_REP_LEGAL = 'oper-rent-btn-rep-legal';

//MODULO: ApprovalConsultationModule ---> //PANTALLA: ApprovalConsultationComponent
export const ACT_APPROVAL_BTN_DESTINATIONS = 'act-approval-btn-destinations';
export const ACT_APPROVAL_BTN_GUARANTEE = 'act-approval-btn-guarantee';
export const ACT_APPROVAL_BTN_OBSERVATIONS = 'act-approval-btn-observations';
export const ACT_APPROVAL_BTN_VALUES = 'act-approval-btn-values';
export const ACT_APPROVAL_BTN_ECO_GROUP = 'act-approval-btn-eco-group';

//PANTALLA: ApprovalValuesComponent
export const ACT_APPROVAL_BTN_DETAILS_VALUES = 'act-approval-btn-details-values';

//MODULO: LeaseModule
//PANTALLA: CreateOperationComponent
export const LEASE_BTN_CONTINUE = 'lease-btn-continue';

//PANTALLA: CreateLeaseComponent
export const LEASE_BTN_FILE_UPLOAD = 'lease-btn-file-upload';
export const LEASE_BTN_ASSETS_CONTINUE = 'lease-assets-btn-continue';

//PANTALLA: ConsultRentOperationComponent > ConsultRentOperationHomeComponent
export const OPRENT_CONSULT_BTN_DL_DOC = 'oper-rent-consult-btn-dl-doc';
export const OPRENT_CONSULT_BTN_OP_EXPIRY = 'oper-rent-consult-btn-op-expiry';
export const OPRENT_CONSULT_BTN_OP_NUMBERS = 'oper-rent-consult-btn-op-numbers';
export const OPRENT_CONSULT_BTN_WALLET_CLIENTS = 'oper-rent-consult-btn-wallet-client';
export const OPRENT_CONSULT_BTN_DETAILS_COST = 'oper-rent-consult-btn-details-cost';


//PANTALLA: ConsultRentOperationComponent > OperationNumberComponent
export const OPRENT_CONSULT_BTN_OP_NUMBERS_CONSULT = 'oper-rent-consult-btn-op-numbers-consult';
export const OPRENT_CONSULT_BTN_OP_NUMBERS_CONTINUE = 'oper-rent-consult-btn-op-numbers-continue';

//PANTALLA: ConsultRentOperationComponent > DownloadDocumentsComponent
export const OPRENT_CONSULT_BTN_DOWNLOAD_DOC_CONSULT =
  'oper-rent-consult-btn-download-doc-continue';
export const OPRENT_CONSULT_BTN_DOWNLOAD_DOC = 'oper-rent-consult-btn-download-doc';

//PANTALLA: ConsultRentOperationComponent > OperationClientComponent
export const OPRENT_CONSULT_BTN_OP_CLIENT_CONSULT = 'oper-rent-consult-btn-op-client-consult';
export const OPRENT_CONSULT_BTN_OP_CLIENT_CONTINUE = 'oper-rent-consult-btn-op-client-continue';

//PANTALLAS Y BOTONES
export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_CODE = '';
export const DEFAULT_NAME = '';
