import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiHelperService } from '@services/apihelper/api-helper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AssetTypesWithoutPag,
  Columnas,
  IParametersTypeAssetsType,
  IResponseAssetCreateUpdate,
  TablaActivo,
  TablaTipoActivos,
  TipoActivos,
  TipoDeActivosList,
} from 'src/app/core/models/AssetTypes';
import { backend } from 'src/paths/development';
import { Numbers } from '../../core/constants/numbers';
import { ICreateUpdateParam } from '@models/ISharedResponse';

@Injectable({
  providedIn: 'root',
})
export class AssetTypeService {
  constructor(private http: ApiHelperService) {}

  /**
   * Crear parametro
   * @param body: ICreateUpdateParam
   * @returns Observable<IResponseAssetCreateUpdate>
   */
  createAssetType({ body }: { body: ICreateUpdateParam }): Observable<IResponseAssetCreateUpdate> {
    return this.http.post(`${backend.assetType.create}`, body);
  }

  /**
   * Actualizar parametro
   * @param body: ICreateUpdateParam
   * @returns Observable<IResponseAssetCreateUpdate>
   */
  updateAssetType({ body }: { body: ICreateUpdateParam }): Observable<IResponseAssetCreateUpdate> {
    return this.http.put(`${backend.assetType.update}`, body);
  }

  /**
   * Get all assetTypes
   * @param page: Paginator of the response
   * @param size: Size of the response elements
   * @returns Observable<AssetType[]>
   */
  getAllAssetTypes(
    page?: number | '',
    size?: number | '',
    estado?: boolean
  ): Observable<IParametersTypeAssetsType | undefined> {
    const pageRequest = page ?? Numbers._0;
    const sizeRequest = size ?? Numbers._5;
    return this.http
      .get(`${backend.assetType.getAll}?page=${pageRequest}&size=${sizeRequest}&estado=${estado}`)
      .pipe(map((response: any) => response as IParametersTypeAssetsType));
  }

  /**
   * Get assetTypes by code
   * @param code: AssetType code
   * @returns Observable<TipoDeActivosList>
   */
  getAssetTypesByCode(code: string): Observable<TipoDeActivosList> {
    return this.http
      .get(`${backend.assetType.getByCode}/${code}`)
      .pipe(map((response: any) => response._embedded.tipoDeActivoses as TipoDeActivosList));
  }

  /**
   * Activate asset by code
   * @param code: AssetType code
   * @returns statusCode: Observable<number>
   */
  activateAssetType(code: string): Observable<number> {
    return this.http
      .put<any>(`${backend.assetType.activate}/${code}`, {}, undefined, {
        observable: 'response',
      })
      .pipe(map((response: HttpResponse<any>) => response.status));
  }

  /**
   * Deactivate asset by code
   * @param code: AssetType code
   * @returns statusCode: Observable<number>
   */
  deactivateAssetType(code: string): Observable<number> {
    return this.http
      .put<any>(`${backend.assetType.deactivate}/${code}`, {}, undefined, {
        observe: 'response',
      })
      .pipe(map((response: HttpResponse<any>) => response.status));
  }

  /**
   * Get all assetTypes
   * @returns Observable<TablaActivo>
   */
  getAllColumnsAsset(): Observable<TablaActivo | null> {
    return this.http
      .get(`${backend.assetType.getAllColumns}`)
      .pipe(map((response: TablaActivo) => response as TablaActivo));
  }

  getColumnsByType(tipo: string, existente: boolean): Observable<TablaTipoActivos> {
    return this.http
      .get(`${backend.assetType.getColumsnByType}?tipo=${tipo}&existente=${existente}`)
      .pipe(map((response: TablaTipoActivos) => response as TablaTipoActivos));
  }

  /**
   * Get all getAllAssetTypesWithoutPag
   * @returns Observable<AssetTypesWithoutPag>
   */
  getAllAssetTypesWithoutPag(): Observable<AssetTypesWithoutPag> {
    return this.http
      .get(`${backend.assetType.getAllAssetsTypeWithoutPag}`)
      .pipe(map((response: AssetTypesWithoutPag) => response));
  }

  saveConfigParametersColumns({
    tipo,
    existente,
    columns,
  }: {
    [key: string]: any;
  }): Observable<TipoActivos> {
    return this.http
      .post<Columnas[]>(
        `${backend.assetType.saveConfigColumns}?tipo=${tipo}&existente=${existente}`,
        columns
      )
      .pipe(map((response: any) => response as TipoActivos));
  }
}
