import { Directive, ElementRef } from '@angular/core';
import { Numbers } from 'src/app/core/constants/numbers';

@Directive({
  selector: '[OnlylettersAndNumbers]',
})
export class LettersAndNumbersDirective {
  constructor(public elementRef: ElementRef) {
    this.validate(elementRef);
  }

  validate(elementRef: ElementRef) {
    elementRef.nativeElement.onkeypress = (evt: KeyboardEvent) => {
      const tecla = evt.key;
      const lettersAndNumbers =
        'qwertyuiopasdfghjklñzxcvbnmQWERTYUIOPASDFGHJKLÑZXCVBNMáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ1234567890. ';
      const especiales = [Numbers._131];
      let teclaEspecial = false;
      for (const i in especiales) {
        if (evt.key.charCodeAt(Numbers._0) == especiales[i]) {
          teclaEspecial = true;
          break;
        }
      }
      if (lettersAndNumbers.indexOf(tecla) == -Numbers._1 && !teclaEspecial) {
        evt.preventDefault();
      }
    };
  }
}
