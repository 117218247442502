import { Component, OnInit } from '@angular/core';
import { Numbers } from '@constants/numbers';
import { DataService } from '@services/data/data.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentDateTime: Date = new Date();
  ipAdr: any;
  date: number = Date.now();

  constructor(private dataService: DataService) {
    // This is intentional
  }

  ngOnInit(): void {
    this.ipAddress();
    this.relojLive();
  }

  async ipAddress() {
    const URL_API = 'https://api.ipify.org/?format=json';
    await fetch(URL_API)
      .then((respuestaRaw) => respuestaRaw.json())
      .then((respuesta) => {
        this.ipAdr = respuesta.ip;
        this.dataService.setCurrentIp(this.ipAdr);
      });
  }

  relojLive() {
    interval(Numbers._1000).subscribe(() => {
      this.currentDateTime = new Date();
    });
  }
}
