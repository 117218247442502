import { Action, createReducer, on } from '@ngrx/store';
import { LoginAction } from './actions';

interface LoginState {
  token: string | null;
  pantallas: any | null;
  error: string | null;
  refresh: boolean;
}

export const loginStateGenerator: () => LoginState = () =>
  JSON.parse('{"token": null,"pantallas": null, "error": null, "refresh": false}');

export const initialState: LoginState = loginStateGenerator();

const featureReducer = createReducer(
  initialState,
  on(LoginAction.setToken, (state, { token, pantallas }) => ({
    ...state,
    token,
    pantallas,
  })),

  on(LoginAction.onError, (state, { error }) => ({
    ...state,
    error,
  })),

  on(LoginAction.resetState, (_) => loginStateGenerator()),

  on(LoginAction.findToken, (state) => ({
    ...state,
  })),

  on(LoginAction.refreshToken, (state) => ({
    ...state,
    refresh: true,
  }))
);

function Reducer(state: LoginState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getToken = (state: LoginState) => (state ? state.token : '');
const getPantallas = (state: LoginState) => (state ? state.pantallas : []);
const getError = (state: LoginState) => (state ? state.error : '');
const getRefresh = (state: LoginState) => (state ? state.refresh : false);

export const LoginReducer = {
  Reducer,
  getToken,
  getPantallas,
  getError,
  getRefresh,
};

export { LoginState };
