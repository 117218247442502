import { createAction, props } from '@ngrx/store';

enum LoginActionType {
  setTokenAction = '[LoginComponent] Set token',
  resetStateAction = '[LoginComponent] Reset state',
  errorAction = '[LoginComponent] Error action',
  refreshTokenAction = '[LoginComponent] Refresh token action',
  findTokenAction = '[LoginComponent] Find token',
  findPantallasAction = '[LoginComponent] Find pantallas',
}

const setToken = createAction(
  LoginActionType.setTokenAction,
  props<{ token: string; pantallas: any }>()
);
const resetState = createAction(LoginActionType.resetStateAction, props<any>());

const onError = createAction(LoginActionType.errorAction, props<{ error: string }>());

const refreshToken = createAction(LoginActionType.refreshTokenAction, props<any>());

const findToken = createAction(LoginActionType.findTokenAction, props<any>());

export const LoginAction = {
  findToken,
  setToken,
  resetState,
  onError,
  refreshToken,
};
