import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalService } from '@azure/msal-angular';
import { ADMIN_MODULE, OPRENT_MODULE, REPORTS_MODULE } from '@constants/frontend';
import { Numbers } from '@constants/numbers';
import { LoginFacadeService } from '@modules/autenticacion/login/store/login.facade.service';
import { ApiHelperService } from '@services/apihelper/api-helper.service';
import { BcsModalComponent } from '@shared/components/bcs-modal/bcs-modal.component';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginService } from '../../services/login/login.service';
import { RentOperationFacadeService } from './rent-operation/store/reducer.facade.service';
import { SharedDataService } from '@services/shared-data/shared-data.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
})
export class MasterComponent implements OnInit {
  private destroySubs$: Subject<void> = new Subject<void>();
  @ViewChild('modalErrorHelper') modalErrorHelper!: BcsModalComponent;
  helper: any;
  tokenData: any;
  adminModule: boolean = false;
  operacionRenta: boolean = false;
  suscripcion!: Subscription;
  reportsModule: boolean = false;
  idModalTimerInterval: any = '';
  idCloseSessionTimeout: any = '';
  OPRENT_MODULE_PARAM = OPRENT_MODULE;
  ADMIN_MODULE_PARAM = ADMIN_MODULE;
  REPORT_MODULE_PARAM = REPORTS_MODULE;
  modalContent = 'Tu sesión está por terminar. Desea continuar?';
  today = new Date();
  date = this.today.toLocaleDateString('es-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  timer = Numbers._30;
  currentDateTime: string = '';
  tabAssetsType: boolean = false;
  tabProduct: boolean = false;
  tabChannels: boolean = false;
  tabFunds: boolean = false;
  tabOperationCharact: boolean = false;
  data: any;
  routerSubscription: Subscription;
  moduleName = '';
  @ViewChild('modalRefreshToken') modalRefreshToken!: BcsModalComponent;
  isModalRefreshTokenOpen = false;
  disabledMenuItem = false;
  hiddenModules = true;
  errorMessage = 'Error inesperado ¡Por favor intente de nuevo!';
  //TIMER
  title = '';
  countdownInactividad: number = Numbers._240; // 240 segundos = 4 minutos
  countdownSesion: number = Numbers._390; // 390 segundos = 6.5 minutos
  countdownConfirmation: number = Numbers._30;
  interval: any;
  interval2: any;
  interval3: any;
  userActive: boolean = true;
  messageModalRefresh = 'Su sesión está apunto de terminar.';

  constructor(
    public authService: MsalService,
    private rentOperationFacade: RentOperationFacadeService,
    private loginService: LoginService,
    private loginFacadeService: LoginFacadeService,
    private router: Router,
    private apiHelperService: ApiHelperService,
    private sharedDataService: SharedDataService
  ) {
    this.helper = new JwtHelperService();
    this.routerSubscription = new Subscription();
    this.handleErrorApiHelper();
    this.startCountdownInactividad();
    this.startCountdownLogOut();
  }

  ngOnInit(): void {
    this.initParameters();
    this.subscribeToRouterEvents();
    this.reloj();
  }

  startCountdownLogOut() {
    // Limpia cualquier intervalo anterior
    clearInterval(this.interval2);
    this.countdownSesion = Numbers._390;
    this.interval2 = setInterval(() => {
      this.countdownSesion--;
      if (this.countdownSesion === Numbers._0) {
        this.modalRefreshToken.closeModal();
        clearInterval(this.interval2);
        clearInterval(this.interval); // Detener inactividad si está corriendo
        const message = 'Su sesión expirará';
        this.startCountdownConfirmation(message);
      }
    }, Numbers._1000);
  }

  startCountdownInactividad() {
    // Limpia cualquier intervalo anterior
    clearInterval(this.interval);
    this.countdownInactividad = Numbers._240;
    this.interval = setInterval(() => {
      if (!this.userActive) {
        this.countdownInactividad--;
        if (this.countdownInactividad === Numbers._0) {
          this.modalRefreshToken.closeModal();
          clearInterval(this.interval);
          const message = 'No hemos detectado interacción.';
          this.startCountdownConfirmation(message);
        }
      } else {
        this.resetCountdown();
      }
    }, Numbers._1000);
  }

  startCountdownConfirmation(message: string) {
    // Limpia cualquier intervalo anterior
    clearInterval(this.interval3);
    this.messageModalRefresh = message;
    this.modalRefreshToken.openModal();
    this.countdownConfirmation = Numbers._30;
    this.interval3 = setInterval(() => {
      this.countdownConfirmation--;
      if (this.countdownConfirmation === Numbers._0) {
        clearInterval(this.interval3);
        if (message.includes('No hemos detectado interacción')) {
          this.modalRefreshToken.closeModal();
          this.changePage('');
          this.resetCountdown();
          this.startCountdownInactividad();
          this.rentOperationFacade.cleanStorage();
          //Ejecutar la liberacion de activos
        } else {
          this.logOut();
        }
      }
    }, Numbers._1000);
  }

  resetCountdown() {
    this.countdownInactividad = Numbers._240;
    this.userActive = false;
  }

  /* el metodo onUserActivity es el
   * manejador para ambos eventos: mousemove y keydown
   */
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  onUserActivity(event: Event) {
    if (event) {
      this.userActive = true;
    }
  }

  getPantallasSubscription = (pantallas: any) => {
    this.data.pantallas = pantallas;
    this.adminModule = this.validateIfExist(pantallas.find(this.pantallasAdminModuleFilter));
    this.operacionRenta = this.validateIfExist(pantallas.find(this.pantallasOprentModuleFilter));
    this.reportsModule = this.validateIfExist(pantallas.find(this.pantallasReportsModuleFilter));
  };

  getTokenSubscription = (token: any) => {
    this.data = this.helper.decodeToken(token);
    if (!this.data) {
      return;
    }
    this.loginFacadeService.getPantallas().subscribe({
      next: this.getPantallasSubscription,
    });
  };

  initParameters = () => {
    this.loginFacadeService.getToken().subscribe({
      next: this.getTokenSubscription,
    });
  };

  /**
   * Inicializa la suscripción a los eventos del router
   * para manejar la habilitación/deshabilitación del menú
   */
  subscribeToRouterEvents(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => this.handleNavigationEnd(event));
  }

  /**
   * Maneja el evento NavigationEnd para determinar si deshabilitar el elemento del menú
   * @param event - El evento NavigationEnd
   */
  handleNavigationEnd(event: NavigationEnd): void {
    const excludedUrls = [
      '/app/operacion-renta/crear/arrendamiento/entregas-activos',
      '/app/operacion-renta/crear/arrendamiento/entregas-solucion',
      '/app/operacion-renta/crear/arrendamiento/consultar-operacion',
    ];

    // Determina si el elemento del menú debe ser deshabilitado basado en la URL actual
    this.disabledMenuItem = this.shouldDisableMenuItem(event.url, excludedUrls);
  }

  /**
   * Determina si el elemento del menú debe ser deshabilitado basado en la URL actual
   * @param url - La URL actual
   * @param excludedUrls - Una lista de URLs que no deben deshabilitar el elemento del menú
   * @returns {boolean} - Verdadero si el elemento del menú debe ser deshabilitado, falso de lo contrario
   */
  shouldDisableMenuItem(url: string, excludedUrls: string[]): boolean {
    const isCreatingRentOperation = url.includes('/app/operacion-renta/crear');
    const isExcludedUrl = excludedUrls.some((excludedUrl) => url.includes(excludedUrl));

    // Deshabilita el elemento del menú si se está creando una operación de renta y no está en una URL excluida
    return isCreatingRentOperation && !isExcludedUrl;
  }

  reloj() {
    interval(Numbers._1000).subscribe(() => {
      const now = new Date();
      this.currentDateTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    });
  }

  pantallasAdminModuleFilter = (x: any) => x.modulo == ADMIN_MODULE;
  pantallasOprentModuleFilter = (x: any) => x.modulo == OPRENT_MODULE;
  pantallasReportsModuleFilter = (x: any) => x.modulo == REPORTS_MODULE;

  changePage(module: string): void {
    this.rentOperationFacade.cleanStorage();
    this.loginService.updateData([]);
    if (module != '') {
      if (module == ADMIN_MODULE) {
        this.loginService.updateData(
          this.data.pantallas.filter((a: any) => a.modulo == ADMIN_MODULE)
        );
        this.router.navigate(['/app/administracion']);
      } else if (module == OPRENT_MODULE) {
        this.loginService.updateData(
          this.data.pantallas.filter((a: any) => a.modulo == OPRENT_MODULE)
        );
        this.router.navigate(['/app/operacion-renta']);
      } else if (module == REPORTS_MODULE) {
        this.loginService.updateData(
          this.data.pantallas.filter((a: any) => a.modulo == REPORTS_MODULE)
        );
        this.router.navigate(['/app/reportes']);
      }
    } else {
      this.router.navigate(['app']);
    }
    this.sharedDataService.resetCurrentMenuTab();
  }

  checkTokenAction(event: any): void {
    // Cerrar el modal
    this.modalRefreshToken.closeModal();
    this.isModalRefreshTokenOpen = false;

    // Resetear countdownConfirmation y intervalos
    clearInterval(this.interval3);
    this.countdownConfirmation = Numbers._30;

    if (event === Numbers._0) {
      // Si el evento es 0, cerrar sesión
      this.authService.logout();
      return;
    }

    if (this.messageModalRefresh.includes('No hemos detectado interacción')) {
      // Si el mensaje es de inactividad, reiniciar el countdown de inactividad
      this.startCountdownInactividad();
    } else {
      // De lo contrario, reiniciar el countdown de logout y refrescar token
      this.startCountdownLogOut();
      this.startCountdownInactividad();
      this.loginFacadeService.refreshToken().subscribe();
    }
  }

  validateIfExist(listModule: any): boolean {
    return listModule != null;
  }

  handleErrorApiHelper(): void {
    this.apiHelperService
      .getErrorSubject()
      .pipe(takeUntil(this.destroySubs$))
      .subscribe((error) => {
        if (error?.message?.includes('Unauthorized')) {
          this.hiddenModules = false;
          this.errorMessage = 'Su sesión ha expirado por favor ingrese de nuevo';
          this.modalErrorHelper.openModal();
          return;
        }
        if (error?.statusText?.includes('Unknown Error')) {
          this.hiddenModules = false;
          this.errorMessage =
            'Su sesión será cerrada, <br/> si persiste el error, comunicarse con el administrador';
          this.modalErrorHelper.openModal();
        }
      });
  }

  handleErrorRequestHelper(event: number): void {
    if (event) {
      this.logOut();
      this.modalErrorHelper.closeModal();
    }
  }

  logOut: Function = () => this.authService.logout();
}
