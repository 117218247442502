import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map } from 'rxjs/operators';
import { LoginAction } from './actions';
import { LoginService } from '@services/login/login.service';
import { of } from 'rxjs';

@Injectable()
export class LoginEffects {
  constructor(private actions$: Actions, private loginService: LoginService) {}

  /** findToken **/
  findTokenEffectMapper = ({ token, pantallas }: { [key: string]: any }) =>
    LoginAction.setToken({ token, pantallas });

  findTokenEffectErrorMapper = ({ token }: { [key: string]: any }) =>
    of(LoginAction.onError({ error: token }));

  findTokenEffectSwitchMapFunction = () =>
    this.loginService
      .loginRequest()
      .pipe(map(this.findTokenEffectMapper), catchError(this.findTokenEffectErrorMapper));

  findTokenEffectFunction = () =>
    this.actions$.pipe(
      ofType(LoginAction.findToken),
      switchMap(this.findTokenEffectSwitchMapFunction)
    );
  findTokenEffect$ = createEffect(this.findTokenEffectFunction);
  /** findToken **/

  /** refreshToken **/
  refreshTokenEffectMapper = ({ token, pantallas }: { [key: string]: any }) =>
    LoginAction.setToken({ token, pantallas });

  refreshTokenEffectErrorMapper = ({ token }: { [key: string]: any }) =>
    of(LoginAction.onError({ error: token }));

  refreshTokenEffectSwitchMapFunction = () =>
    this.loginService
      .refreshRequest()
      .pipe(map(this.refreshTokenEffectMapper), catchError(this.refreshTokenEffectErrorMapper));

  refreshTokenEffectFunction = () =>
    this.actions$.pipe(
      ofType(LoginAction.refreshToken),
      switchMap(this.refreshTokenEffectSwitchMapFunction)
    );
  refreshTokenEffect$ = createEffect(this.refreshTokenEffectFunction);
  /** refreshToken **/
}
