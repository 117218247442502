import { Injectable } from '@angular/core';
import { TablaTipoActivos } from '@models/AssetTypes';
import { DataColumn } from '@models/DataColumn';

@Injectable({
  providedIn: 'root',
})
export class DataColumnService {
  existentDataColumnMapper(dataColumn: TablaTipoActivos): DataColumn[] {
    const existentEditables = dataColumn.tablaactivos?.columnasEditables.map(({ alias }) => alias);

    return dataColumn.tablaactivos?.columnas
      .map(({ alias, ...column }) => ({
        ...column,
        alias,
        isEditable: existentEditables.includes(alias),
      }))
      .sort((a, b) => a.id - b.id);
  }

  nonExistentDataColumnMapper(dataColumn: TablaTipoActivos): DataColumn[] {
    const nonExistentEditables = dataColumn.tablaactivos?.columnasEditables.map(
      ({ alias }) => alias
    );
    return dataColumn.tablaactivos?.columnas
      .map(({ alias, ...column }) => ({
        ...column,
        alias,
        isEditable: nonExistentEditables.includes(alias),
      }))
      .sort((a, b) => a.id - b.id);
  }
}
