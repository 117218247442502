import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ActiveAccount } from 'src/app/core/models/ActiveAccount';
import { TokenService } from 'src/app/services/token-JWT/token.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  datos: ActiveAccount = new ActiveAccount();

  constructor(
    private tokenService: TokenService,
    private msalService: MsalService
  ) {
    // This is intentional
  }

  ngOnInit(): void {
    this.getAccountActive();
  }

  getAccountActive() {
    this.tokenService.getAccountActive().then((response) => {
      this.datos = response;
    });
  }

  logout() {
    this.msalService.logout();
    sessionStorage.clear();
    localStorage.clear();
  }
}
