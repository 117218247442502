import { InjectionToken } from '@angular/core';
import { RentOperationReducer, RentOperationState } from './reducers';
import { Action, ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
interface State {
  rentOperation: RentOperationState;
}
export const factoryFunction = () => ({
  rentOperation: RentOperationReducer.Reducer,
});

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>(
  'Root reducers token',
  {
    factory: factoryFunction,
  }
);

// ======================================[RENT OPERATION]======================================\\
//#region NEW CUSTOMER
const getRentOperationState = createFeatureSelector<RentOperationState>('rentOperation');

const getListCustomers = createSelector(
  getRentOperationState,
  RentOperationReducer.getListCustomers
);

const getSelectedClient = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedClient
);

const getApprovalValuesList = createSelector(
  getRentOperationState,
  RentOperationReducer.getApprovalValuesList
);

const getRentOperationValues = createSelector(
  getRentOperationState,
  RentOperationReducer.getRentOperationValues
);

const getArrendatariosList = createSelector(
  getRentOperationState,
  RentOperationReducer.getArrendatarios
);

// ======================================[LEGAL FILE]======================================\\
const getLegalFileData = createSelector(
  getRentOperationState,
  RentOperationReducer.getLegalFileData
);

const getLegalRepresentativeData = createSelector(
  getRentOperationState,
  RentOperationReducer.getLegalRepresentativeData
);

const getInfActApprovalData = createSelector(
  getRentOperationState,
  RentOperationReducer.getInfActApprovalData
);

const getApprovalValuesData = createSelector(
  getRentOperationState,
  RentOperationReducer.getApprovalValuesData
);

const getDetailValuesData = createSelector(
  getRentOperationState,
  RentOperationReducer.getDetailValuesData
);

const getDestinationsData = createSelector(
  getRentOperationState,
  RentOperationReducer.getDestinationsData
);

const getWarrantyData = createSelector(getRentOperationState, RentOperationReducer.getWarrantyData);

const getObservationsData = createSelector(
  getRentOperationState,
  RentOperationReducer.getObservationsData
);

const getEconomicGroupData = createSelector(
  getRentOperationState,
  RentOperationReducer.getEconomicGroupData
);

const getProducstByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getProducstByFilter
);

const getSelectedProductByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedProductByFilter
);

const geRespBusiness = createSelector(getRentOperationState, RentOperationReducer.getRespBusiness);

const getSelectedRespBusiness = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedRespBusiness
);

const getAccountManagerByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getAccountManagerByFilter
);

const getSelectedAccountManagerByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedAccountManagerByFilter
);

const getAssetsByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getAssetsByFilter
);

const getSelectedAssetsByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedAssetsByFilter
);

const getAssetsCondition = createSelector(
  getRentOperationState,
  RentOperationReducer.getAssetsCondition
);

const getSelectedAssetsCondition = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedAssetsCondition
);

const getChannelsByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getChannelsByFilter
);
const getSelectedChannelsByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedChannelsByFilter
);

const getFundsByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getFundsByFilter
);

const getSelectedFundsByFilter = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedFundsByFilter
);

const getOperationType = createSelector(
  getRentOperationState,
  RentOperationReducer.getOperationType
);

const getOperationCharacteristic = createSelector(
  getRentOperationState,
  RentOperationReducer.getOperationCharacteristic
);

const getRatesSelector = createSelector(getRentOperationState, RentOperationReducer.getRates);

const getSelectedRateSelector = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedRate
);

const getCodeMoraSelector = createSelector(
  getRentOperationState,
  RentOperationReducer.getCodeMoraByFilter
);

const getSelectedCodeMoraSelector = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedCodeMoraByFilter
);
const getCreateLease = createSelector(getRentOperationState, RentOperationReducer.getCreateLease);

const getSelectedAccountManager = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedAccountManager
);

const getSocietyValidity = createSelector(
  getRentOperationState,
  RentOperationReducer.getSocietyValidity
);

const getActiveApproves = createSelector(
  getRentOperationState,
  RentOperationReducer.getActiveApproves
);

const getDestinyList = createSelector(getRentOperationState, RentOperationReducer.getDestinyList);
const getNameProjectList = createSelector(
  getRentOperationState,
  RentOperationReducer.getNameProjectList
);

const getUnitsActiveList = createSelector(
  getRentOperationState,
  RentOperationReducer.getUnitsActiveList
);
const getActivesMarkList = createSelector(
  getRentOperationState,
  RentOperationReducer.getActivesMarkList
);
const getReserveId = createSelector(getRentOperationState, RentOperationReducer.getReserveId);
const getError = createSelector(getRentOperationState, RentOperationReducer.getError);

const getOpByContractNumber = createSelector(
  getRentOperationState,
  RentOperationReducer.getOpByContractNumber
);

const getCurrentRates = createSelector(getRentOperationState, RentOperationReducer.getCurrentRates);

const getSelectedDataFnlConds = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedDataFnlConds
);
const getCreateRentOperationDto = createSelector(
  getRentOperationState,
  RentOperationReducer.getCreateRentOperationDto
);

const getCreateOperationDto = createSelector(
  getRentOperationState,
  RentOperationReducer.getCreateOperationDto
);

const getCreateLeaseDto = createSelector(
  getRentOperationState,
  RentOperationReducer.getCreateLeaseDto
);

const getRentOperationDto = createSelector(
  getRentOperationState,
  RentOperationReducer.getRentOperationDto
);

const getListaFirmantes = createSelector(
  getRentOperationState,
  RentOperationReducer.getListaFirmantes
);

const getReservedPlates = createSelector(
  getRentOperationState,
  RentOperationReducer.getReservedPlates
);

const getExistentDataColumn = createSelector(
  getRentOperationState,
  RentOperationReducer.getExistentDataColumn
);

const getNonExistentDataColumn = createSelector(
  getRentOperationState,
  RentOperationReducer.getNonExistentDataColumn
);

const getReservedId = createSelector(getRentOperationState, RentOperationReducer.getReservedId);

const getSelectedCurrentRate = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedCurrentRate
);

const getSelectedRadjPeriodicity = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedRadjPeriodicity
);

const getSelectedPaymentPeriodicity = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedPaymentPeriodicity
);

const getSelectedReadjFormula = createSelector(
  getRentOperationState,
  RentOperationReducer.getSelectedReadjFormula
);

const getUrlOrigen = createSelector(getRentOperationState, RentOperationReducer.getUrlOrigen);

export const fromRentOperation = {
  getExistentDataColumn,
  getReservedId,
  getNonExistentDataColumn,
  getReservedPlates,
  getRentOperationDto,
  getListaFirmantes,
  getCreateLeaseDto,
  getCreateOperationDto,
  getCreateRentOperationDto,
  getDestinyList,
  getReserveId,
  getActiveApproves,
  getSelectedAccountManager,
  getRentOperationState,
  getListCustomers,
  getSelectedClient,
  getApprovalValuesList,
  getRentOperationValues,
  getError,
  getLegalFileData,
  getLegalRepresentativeData,
  getInfActApprovalData,
  getApprovalValuesData,
  getDetailValuesData,
  getDestinationsData,
  getWarrantyData,
  getObservationsData,
  getEconomicGroupData,
  getProducstByFilter,
  getSelectedProductByFilter,
  geRespBusiness,
  getSelectedRespBusiness,
  getAccountManagerByFilter,
  getSelectedAccountManagerByFilter,
  getAssetsByFilter,
  getSelectedAssetsByFilter,
  getAssetsCondition,
  getSelectedAssetsCondition,
  getChannelsByFilter,
  getSelectedChannelsByFilter,
  getFundsByFilter,
  getSelectedFundsByFilter,
  getOperationType,
  getOperationCharacteristic,
  getRatesSelector,
  getSelectedRateSelector,
  getCodeMoraSelector,
  getSelectedCodeMoraSelector,
  getCreateLease,
  getSocietyValidity,
  getArrendatariosList,
  getNameProjectList,
  getUnitsActiveList,
  getActivesMarkList,
  getOpByContractNumber,
  getCurrentRates,
  getSelectedDataFnlConds,
  getSelectedCurrentRate,
  getSelectedRadjPeriodicity,
  getSelectedPaymentPeriodicity,
  getSelectedReadjFormula,
  getUrlOrigen,
};
export { State };
