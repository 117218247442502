// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const urlServer = 'https://leasing-int-dev.apps.ambientesbc.com/arrendamientodigi';

export const environment = {
  production: false,
  clientId: 'b0e927b6-8321-4250-9b18-c799ef4c5563',
  authority: 'https://login.microsoftonline.com/428f4e2e-13bf-4884-b364-02ef9af41a1d',
  URL_API: urlServer,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
