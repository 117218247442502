import { InjectionToken } from '@angular/core';
import {
  LoginReducer,
  LoginState,
} from '@modules/autenticacion/login/store/reducers';
import {
  RentOperationReducer,
  RentOperationState,
} from '@modules/master/rent-operation/store/reducers';
import { Action, ActionReducerMap } from '@ngrx/store';
interface State {
  rentOperation: RentOperationState;
  login: LoginState;
}

export const factoryReducers = {
  factory: () => ({
    rentOperation: RentOperationReducer.Reducer,
    login: LoginReducer.Reducer,
  }),
};
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', factoryReducers);
